import dayjs from "dayjs";

const monthMap = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

const reverseMonthMap = Object.fromEntries(
  Object.entries(monthMap).map(([key, value]) => [value, key])
);

export const convertFromDate = (dateString) => {
  const date = dayjs(dateString);
  const formattedDate = date.format("MMM DD YYYY");

  const dateParts = formattedDate.split(" ");
  const monthName = dateParts[0];
  const day = dateParts[1];
  const year = dateParts[2];
  const month = monthMap[monthName];
  let fromDate = `${year}-${month}-${day}`;

  return fromDate;
};

export const convertFromTime = (getTime) => {
  const hour = getTime?.$H;
  const minutes = getTime?.$m;
  const sec = getTime?.$s;
  const hoursDigit = hour?.toString().length === 1;
  const minutesDigits = minutes?.toString().length === 1;
  const secDigits = sec?.toString().length === 1;

  let fromTime = `${hoursDigit ? 0 : ""}${hour}:${
    minutesDigits ? 0 : ""
  }${minutes}:${secDigits ? 0 : ""}${sec}.000+0000`;

  return fromTime;
};

export const convertToDate = (dateString) => {
  const date = dayjs(dateString);
  const formattedDate = date.format("MMM DD YYYY");

  const dateParts = formattedDate.split(" ");
  const monthName = dateParts[0];
  const day = dateParts[1];
  const year = dateParts[2];
  const month = monthMap[monthName];
  let toDate = `${year}-${month}-${day}`;

  return toDate;
};

export const convertToTime = (getTime) => {
  const hour = getTime?.$H;
  const minutes = getTime?.$m;
  const sec = getTime?.$s;
  const hoursDigit = hour?.toString().length === 1;
  const minutesDigits = minutes?.toString().length === 1;
  const secDigits = sec?.toString().length === 1;
  let toTime = `${hoursDigit ? 0 : ""}${hour}:${
    minutesDigits ? 0 : ""
  }${minutes}:${secDigits ? 0 : ""}${sec}.000+0000`;

  return toTime;
};

export const reverseDateTime = (dateTimeString) => {
  const date = dayjs(dateTimeString);
  const formattedDate = date.format("DD MMM");

  // Return the formatted date as DD MM YYYY"
  return `${formattedDate} `;
};

export function getMiddleTime(startTime, endTime) {
  // Convert the input strings to Date objects
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  // Validate the input dates
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error("Invalid date format. Please provide valid ISO 8601 date strings.");
  }

  // Calculate the middle time
  const middleTime = new Date((startDate.getTime() + endDate.getTime()) / 2);

  // Return the middle time as an ISO 8601 string
  return middleTime.toISOString();
}

export function getInitialDate(){
  let today = new Date();
  let sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  let todayEndTime = new Date();
  todayEndTime.setHours(23, 59, 49, 999);

  return {
    sevenDaysAgo: sevenDaysAgo.toISOString().replace("T", " ").replace("Z", ""),
    todayEndTime: todayEndTime.toISOString().replace("T", " ").replace("Z", "")
  };
}