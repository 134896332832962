import React, { useEffect, useState } from "react";
import "./AddPaymentProcessorModal.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Wallet from "../Icons/Wallet";
import Close from "../Icons/Close";
import Radio from "../Icons/Radio";
import SelectedRadio from "../Icons/SelectedRadio";
import { URL, PROCESSOR_ENDPOINT } from "constants/Constants";
import { httpClient } from "utils/HttpClient";
import { processors } from "utils/processors";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "648px",
};

const Item = ({ selected, item }) => {
  const title = (
    item?.processorCode?.charAt(0)?.toUpperCase() +
    item?.processorCode?.slice(1)?.toLocaleLowerCase()
  ).replaceAll("_", " ");
  // console.log(selected, item, "Item111");

  return (
    <div
      className={`payment-processor-item payment-processor-item-${
        selected ? "selected" : "not-selected"
      }`}
    >
      <div className="payment-processor-item-selection-container">
        <div className="payment-processor-item-title-container">
          <div className="payment-processor-item-img-container">
            <img
              loading="lazy"
              src={item?.logo_url}
              className="payment-processor-item-img"
            />
          </div>
          <div className="payment-processor-item-title">
            {item?.displayName}
          </div>
        </div>
        <div className="payment-processor-item-radio-container">
          {selected ? <Radio /> : <SelectedRadio />}
        </div>
      </div>
      <div className="payment-processor-item-subtitle">
        {/* Accepts Bank transfer, wallets and more. */}
        {item?.description}
      </div>
    </div>
  );
};

const AddPaymentProcessorModal = ({
  open,
  setOpen,
  addPayment,
  processorData,
  setSelectedItem,
  setGetBackendProcessorData,
}) => {
  const [list, setList] = React.useState([]);
  const [paymentList, setPaymentList] = React.useState();
  const [selected, setSelected] = React.useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  // console.log(processorData, "prcessorData");

  React.useEffect(() => {
    fetchListData();
    fetchSettingsData();
  }, [processorData]);

  function transformPaymentOptions(paymentOptions) {
    const transformed = {};

    for (const [key, value] of Object?.entries(paymentOptions)) {
      transformed[key] = {
        currencies: [],
        defaultOption: false,
        status: "DISABLED",
      };
    }

    return transformed;
  }

  React.useEffect(() => {
    if (selected) {
      const selectedData = paymentList?.find(
        (item) => item.code === selected
      )?.paymentMethods;

      setSelectedItem({
        id: null,
        processorCode: selected,
        status: "ACTIVE",
        name: "",
        processorConfigurations: {
          merchantId: "",
          clientKey: "",
          serverKey: "",
        },
        paymentTypes: transformPaymentOptions(selectedData),
      });
    }
  }, [selected]);

  const fetchListData = () => {
    httpClient
      .fetchData(
        `${URL}${PROCESSOR_ENDPOINT}`,

        "GET"
      )
      .then((settings) => {
        // setPaymentList(
        //   settings.filter((setting) => {
        //     return !processorData?.find(
        //       (item) =>
        //         item?.processorCode?.toLowerCase() ===
        //         setting?.code?.toLowerCase()
        //     );
        //   })
        // );

        setPaymentList(settings);
        // console.log("get Be data", settings);
      });
  };

  const fetchSettingsData = () => {
    // const processorList = processors.filter((setting) => {
    //   return !processorData?.find(
    //     (item) =>
    //       item.processorCode.toLowerCase() ===
    //       setting.processorCode.toLowerCase()
    //   );
    // });
    // setList(processorList);
    // processors.filter((eachItem) => {
    //   return paymentList?.some((each) => {
    //     if (each.code === eachItem.processorCode) {
    //       console.log(eachItem, "each");
    //       setList((prev) => [...prev, each]);
    //       return true;
    //     }
    //   });
    // });
    //setList(processors);
  };

  useEffect(() => {
    const updatedList = [];
    const notInProcessorArray = [];

    paymentList?.forEach((each) => {
      let isMatched = false;

      processors?.forEach((eachItem) => {
        if (
          eachItem?.processorCode?.toLocaleLowerCase() ===
          each.code?.toLocaleLowerCase()
        ) {
          updatedList.push(eachItem);
          isMatched = true;
        }
      });

      if (!isMatched) {
        const getProcessorIcon = each?.code?.toLocaleLowerCase();
        const logoUrl = `logos/${getProcessorIcon}.svg`;
        const notInProcessorObject = {
          displayName: each?.displayName || each?.code,
          processorCode: each.code,
          description: "Accept Cards",
          logo_url: logoUrl,
        };

        notInProcessorArray.push(notInProcessorObject);
      }
    });

    const finalList = [...updatedList, ...notInProcessorArray];

    setList(finalList);
    setGetBackendProcessorData(finalList);
    console.log("Be Data", paymentList);
    console.log("Fe Data", processors);
    console.log("Not In Fe Data:", notInProcessorArray);
    console.log("Final List:", finalList);
    // console.log("updated", updatedList);
  }, [paymentList, processors]);

  const duplicatedList = Array.from(
    new Map(list.map((item) => [item.processorCode, item])).values()
  );

  const newProcessorsFilters = (
    duplicatedList?.sort((a, b) => {
      return a?.processorCode?.localeCompare(b?.processorCode);
    }) || []
  )?.filter((item) => item?.processorCode?.toLowerCase()?.includes(searchTerm));

  // console.log(newProcessorsFilters, "searching filters");
  // console.log(searchTerm, "searchTerm");

  useEffect(() => {
    duplicatedList.map((eachProcessor) => {
      if (eachProcessor?.processorCode?.toLowerCase()?.includes(searchTerm)) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, [searchTerm]);
  // console.log(loader, "loader");

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style} className="add-payment-container modal-content">
        <div className="modal-header">
          <span className="modal-header-container">
            <div className="modal-header-img-container">
              <Wallet />
            </div>
            <div className="modal-header-title-container">
              <div className="modal-header-title">
                {t("ADD_A_PAYMENT_PROCESSOR")}
              </div>
              <div className="modal-header-subtitle">
                {t("SELECT_THE_PAYMENT_PROCESSOR")}
              </div>
            </div>
          </span>
          <span className="modal-header-close" onClick={() => setOpen(false)}>
            <Close />
          </span>
        </div>
        <div class="payment-processor-search">
          <input
            className="my-3 sticky-top"
            // sx={{
            //   width: "100%",
            //   "& .MuiOutlinedInput-root": {
            //     height: "40px",
            //     "& input": {
            //       height: "40px",
            //       padding: "0 14px",
            //     },
            //     marginBottom: "20px",
            //   },
            //   "& .MuiInputLabel-root": {
            //     marginTop: "-0.5rem",
            //     "&.Mui-focused": {
            //       marginTop: "0px",
            //     },
            //   },
            // }}
            placeholder={t("SEARCH")}
            type="search"
            onChange={(e) => {
              const value = e.target.value.toLowerCase();
              setSearchTerm(value);
            }}
          />
        </div>
        <div className="modal-body mt-4">
          <Box
            sx={{
              // flexGrow: 1,
              // gap: "2",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {newProcessorsFilters?.length > 0 ? (
              <Grid
                container
                // spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {newProcessorsFilters?.map((item) => {
                  // console.log(item, "each seaching item");
                  return (
                    <Grid item key={item.processorCode}>
                      <div onClick={() => setSelected(item.processorCode)}>
                        <Item
                          selected={item.processorCode === selected}
                          item={item}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box className="spinner-container pt-3">
                {loader ? (
                  <div className="spinner"></div>
                ) : (
                  "No Matching Processor"
                )}
              </Box>
            )}
          </Box>
        </div>

        <div className="modal-footer">
          <Box
            className="modal-footer-container mb-3 space-between"
            sx={{ gap: 10, width: "100%" }}
            // padding: "0px 30px"
          >
            <button
              className="modal-footer-cancel btn btn-light-primary me-2 mb-2"
              onClick={() => setOpen(false)}
            >
              {t("CANCEL")}
            </button>
            <button
              className="modal-footer-ok btn btn-primary mb-2 w"
              onClick={() => addPayment(selected)}
            >
              {t("ADD_PROCESSOR")}
            </button>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default AddPaymentProcessorModal;
