import React, { useEffect, useState, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

// Import necessary Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the components needed
ChartJS.register(
  CategoryScale, // For the x-axis category scale
  LinearScale, // For the y-axis linear scale
  BarElement, // For bar charts
  PointElement,
  Title, // For chart title
  Tooltip, // For tooltips
  Legend // For the chart legend
);

const TransactionCountChart = ({ analyticsData, type, text }) => {
  const [chartData, setChartData] = useState(null);

  // Use useMemo to efficiently calculate unique types
  const uniqueTypes = useMemo(() => {
    const types = new Set();
    analyticsData.forEach((item) => {
      types.add(item[type]);
    });
    return Array.from(types);
  }, [analyticsData, type]);
  const optionsShort = {
      month: 'short',
      day: '2-digit'
  };
  useEffect(() => {
    // Function to calculate the middle time between start and end dates
    const getMiddleTime = (startDate, endDate) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const middle = new Date(start.getTime() + (end.getTime() - start.getTime()) / 2);
      return middle.toLocaleDateString('en-GB',optionsShort); // Format date as desired
    };

    // Aggregate data based on date and type (paymentMethod or processorCode)
    const aggregatedData = {};

    analyticsData.forEach((item) => {
      const date = getMiddleTime(item.startDate, item.endDate);
      const key = item[type];
      if (!aggregatedData[date]) {
        aggregatedData[date] = {};
      }
      if (!aggregatedData[date][key]) {
        aggregatedData[date][key] = 0;
      }
      aggregatedData[date][key] += item.transactionCount;
    });

    // Prepare the chart data
    const labels = Object.keys(aggregatedData);
    const datasets = uniqueTypes.map((uniqueType) => ({
      label:  uniqueType =  uniqueType.indexOf("_") > 0 ?  uniqueType.split('_').join(' '): uniqueType,
      data: labels.map((date) => aggregatedData[date][uniqueType] || 0),
      backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.6)`,
    }));

    setChartData({
      labels,
      datasets,
    });
  }, [analyticsData, type, uniqueTypes]); // Include uniqueTypes in the dependency array

  if (!chartData) return <p>Loading...</p>;

  const options = {
    responsive: true,
    indexAxis: "x", // Keeps horizontal bars
    scales: {
      x: {
        type: "category", // x-axis is a category type for dates
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        type: "linear", // y-axis is a linear scale for the transaction count
        title: {
          display: true,
          text: "Transaction Count",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Transaction Count by ${text} over Time`,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default TransactionCountChart;