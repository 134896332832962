import React, { useEffect, useState } from "react";
import "./RulesCondition.css";
import {
  Button,
  FormControl,
  Select,
  TextField,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ElementDropdown from "../ElementDropdown";
import MultiSelect from "../MultiSelect";
import { each, get, includes, isEqual } from "lodash";
import Delete from "../../Icons/Delete";
import Group from "../../Icons/Group";
import styled from "@emotion/styled";
import Switch from "@mui/material/Switch";
import { CloseFullscreen } from "@mui/icons-material";
import { tr } from "date-fns/locale";
import { useProcessorData } from "./ProcessorFunctionProvider";
import { getDate } from "date-fns";
import { useTranslation } from "react-i18next";

const dummyJSON = [
  {
    name: "paymentMethod",
    supportedOperations: ["in", "notIn"],
    supportedValues: [
      "CARDS",
      "BANK_TRANSFER",
      "OVO",
      "GO_PAY",
      "DANA",
      "DOKU",
      "ALFAMART",
      "INDOMARET",
      "MANDIRI_CLICKPAY",
      "SHOPEEPAY",
      "SAKUKU",
      "NEXCASH",
      "QRIS",
      "AKULAKU",
      "KREDIVO",
      "LINKAJA",
      "ASTRAPAY",
      "JENIUSPAY",
      "UANGME",
      "INDODANA",
      "ATOME",
      "STCPAY",
      "TABBY",
      "TAMARA",
      "GOOGLE_PAY",
      "POSTPAY",
      "ALIPAY",
      "PAYPAL",
    ],
  },
  {
    name: "paymentMethodDetails.card.brand",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["VISA", "MASTER_CARD", "DISCOVERY", "JCB"],
  },
  {
    name: "paymentMethodDetails.bankTransfer.bankNameCode",
    supportedOperations: ["in", "notIn"],
    supportedValues: [
      "BCA_VA",
      "MANDIRI_VA",
      "BNI_VA",
      "BRI_VA",
      "DANAMON_VA",
      "SINARMAS_VA",
      "PERMATA_VA",
      "CIMB_VA",
      "BJB_VA",
      "BNC_VA",
      "BSS_VA",
      "BSI_VA",
      "ARTAJASA_VA",
      "SAMPOERNA_VA",
    ],
  },
  {
    name: "amount.value",
    supportedOperations: [
      "greaterThan",
      "lessThan",
      "greaterThanEqual",
      "lessThanEqual",
      "equals",
    ],
    supportedValues: null,
  },
  {
    name: "amount.currencyCode",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["IDR"],
  },
  {
    name: "billingAddress.country",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["ID"],
  },
  {
    name: "customer.nationality",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["ID"],
  },
  {
    name: "shippingDetails.address.country",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["ID"],
  },
  {
    name: "transactionType",
    supportedOperations: ["in", "notIn"],
    supportedValues: ["PAYMENT", "CREDIT"],
  },
];

const OPERATION_ENUM = {
  in: "IN",
  notIn: "NOT IN",
  equals: "EQUALS",
  notEquals: "NOT EQUALS",
  greaterThan: "GREATER THAN",
  greaterThanEqual: "GREATER THAN EQUAL",
  lessThan: "LESS THAN",
  lessThanEqual: "LESS THAN EQUAL",
};

function findSubObjectById(obj, idToFind) {
  // If the current object has the id we're looking for, return it
  if (obj.id === idToFind) {
    return obj;
  }

  // If the object has a property 'conditions' which is an array, search within it
  if (Array.isArray(obj.conditions)) {
    for (const condition of obj.conditions) {
      const result = findSubObjectById(condition, idToFind);
      if (result) {
        return result; // Found the sub-object, return it
      }
    }
  }

  // If the object has a property 'condition' which is an object, search within it
  if (obj.condition && typeof obj.condition === "object") {
    return findSubObjectById(obj.condition, idToFind);
  }

  // If we reach here, it means the sub-object wasn't found at this level
  return null;
}

const modifyConditionsByLevel = (conditionObj, path, newValue, operation) => {
  // Base case: if the path is empty, perform the operation directly on the conditionObj
  if (path.length === 0) {
    if (operation === "add") {
      // Add the new condition at the current level
      return {
        ...conditionObj,
        conditions: [newValue, ...(conditionObj.conditions || [])],
      };
    } else if (operation === "update") {
      // Check if the current level has conditions to update
      if (conditionObj.conditions && Array.isArray(conditionObj.conditions)) {
        // Update the condition with the matching id
        const updatedConditions = conditionObj.conditions.map((o) => {
          if (o?.id === newValue?.id) {
            return { ...o, ...newValue };
          }
          return o;
        });
        return { ...conditionObj, conditions: updatedConditions };
      }
    } else if (operation === "delete") {
      // Since we can't delete the root, return null to indicate no operation
      if (
        conditionObj?.id &&
        newValue.condition?.id &&
        conditionObj?.id === newValue.condition?.id
      ) {
        return null;
      }
      if (conditionObj.conditions && Array.isArray(conditionObj.conditions)) {
        // Update the condition with the matching id
        const updatedConditions = conditionObj.conditions.map((o) => {
          if (o?.id === newValue?.id) {
            return null;
          }
          return o;
        });
        return {
          ...conditionObj,
          conditions: updatedConditions.filter(
            (subCondition) => subCondition !== null
          ),
        };
      }
    }
  }
  // Recursive case: if there's a path, dive into the nested conditions
  if (conditionObj.conditions && Array.isArray(conditionObj.conditions)) {
    const [currentIndex, ...restOfPath] = path;
    const updatedConditions = conditionObj.conditions
      .map((subCondition, index) => {
        if (index === currentIndex) {
          // Recurse with the rest of the path
          return modifyConditionsByLevel(
            subCondition,
            restOfPath,
            newValue,
            operation
          );
        }
        return subCondition;
      })
      .filter((subCondition) => subCondition !== null); // Filter out null values if deleting

    return { ...conditionObj, conditions: updatedConditions };
  }
  // Return the condition object if no changes are made
  return conditionObj;
};

function RulesCondition({
  data,
  level,
  setRuleData,
  ruleData,
  indexPath,
  parentObject,
  setParentObject,
  conditionError,
  setConditionError,
  ruleId,
  isEditing,
  isNested,
  selectPaymentMethods,
  savedPaymentMethodsUpdate,
  processorButtonHide,
  addProcessorErrorMsg,
}) {
  const { t } = useTranslation();
  const [paramDropDown, setParamDropDown] = useState([]);
  const [operationsMap, setOperationsMap] = useState(new Map());
  const [valuesMap, setValuesMap] = useState(new Map());
  const [obj, setObj] = useState(data);
  const [nestedOperation, setNestedOperation] = useState(
    obj?.nestedOperation || "AND"
  );
  const [selectErrorMsg, setSelectErrorMsg] = useState(true);
  const [paramValues, setParamValues] = useState({
    param: "",
    operation: "",
    value: "",
  });
  // const [paymentMethods, setPaymentMethods] = useState([]);
  // const { processorData } = useProcessorData();
  // console.log(paramValues, "param");F
  console.log(selectPaymentMethods, "selectPay  methods");
  console.log(savedPaymentMethodsUpdate, "update pay methods");

  useEffect(() => {
    obj?.condition?.conditions?.forEach((each) => {
      const param = each?.param;
      const operation = each?.operation;
      const value = each?.value;
      setParamValues((prev) => ({
        ...prev,
        param,
        operation,
        value,
      }));

      if (each?.value?.length > 0) {
        setSelectErrorMsg(false);
      }
    });
  }, [obj]);

  // useEffect(() => {
  //   dummyJSON.forEach((item) => {
  //     // console.log(item.supportedValues, "item");
  //     if (item.name === "paymentMethod") {
  //       setPaymentMethods(item.supportedValues);
  //     }
  //   });
  // }, [selectPaymentMethods]);

  // useEffect(() => {
  //   selectPaymentMethods?.map((each) => {
  //     if (paymentMethods?.includes(each)) {
  //     }
  //   });
  // }, [selectPaymentMethods]);

  useEffect(() => {
    obj?.condition?.conditions?.forEach((item) => {
      const selectedValues =
        item?.value
          ?.split(",")
          ?.map((i) => ({ label: i, value: i }))
          ?.filter((i) => i.value !== "") || [];

      // setSelectErrorMsg(!selectedValues);
    });
  }, [obj]);

  useEffect(() => {
    if (
      conditionError &&
      !isEqual(ruleData.condition, obj.condition) &&
      isEqual(ruleData.id, ruleId)
    ) {
      if (parentObject && obj.condition.id) {
        const subObj = findSubObjectById(ruleData, obj.condition.id);
        setObj({ condition: { ...subObj } });
      } else setObj({ condition: { ...ruleData.condition } });
      setConditionError(false);
    }
  }, [conditionError]);

  useEffect(() => {
    setParamDropDown(dummyJSON?.map((obj) => obj.name));
    const oMap = new Map();
    const vMap = new Map();

    dummyJSON.forEach((item) => {
      const refactorOperations = item.supportedOperations.map((i) => ({
        label: OPERATION_ENUM[i],
        value: i,
      }));

      const refactorSupportedValues = item.supportedValues?.map((sv) => ({
        label: sv,
        value: sv,
      }));

      oMap.set(item.name, refactorOperations);
      vMap.set(item.name, refactorSupportedValues);
    });

    setOperationsMap(oMap);
    setValuesMap(vMap);
  }, [dummyJSON.length]);

  const handleClick = (value, obj, item = {}) => {
    switch (value) {
      case "S":
        let o = {
          id: new Date().getTime(),
          param: obj.param,
          operation: obj.operation,
          value: obj?.value,
          isError: false,
          errorMessage: "",
        };

        const updatedObj = {
          ...obj,
          condition: {
            ...obj.condition,
            conditions: [o, ...(obj.condition?.conditions || [])],
          },
        };
        setObj(updatedObj);
        if (parentObject) {
          setParentObject((prev) => {
            const newObj = { ...prev };
            let currentLevel = newObj.condition.conditions; // Start at the first level

            // Iterate over the indexPath array, except for the last element
            for (let i = 0; i < indexPath.length - 1; i++) {
              // Ensure that the next level exists or create it if necessary
              if (!currentLevel[indexPath[i]].conditions) {
                currentLevel[indexPath[i]].conditions = [];
              }
              // Move to the next level
              currentLevel = currentLevel[indexPath[i]].conditions;
            }

            // Update the last level with the updated object
            currentLevel[indexPath[indexPath.length - 1]] =
              updatedObj.condition;
            return newObj;
          });
        }
        setRuleData((prevObject) => {
          const newObject = { ...prevObject };
          const updatedCondition = modifyConditionsByLevel(
            newObject.condition,
            indexPath,
            o,
            "add"
          );
          return { ...prevObject, condition: updatedCondition };
        });
        break;
      case "N":
        let ob = {
          id: new Date().getTime(),
          nestedOperation: nestedOperation,
          conditions: [
            {
              id: new Date().getTime(),
              param: obj.param,
              operation: obj.operation,
              value: obj?.value,
              isError: false,
              errorMessage: "",
            },
          ],
        };
        const updatedObj1 = {
          ...obj,
          condition: {
            ...obj.condition,
            conditions: [ob, ...(obj.condition.conditions || [])],
          },
        };
        setObj(updatedObj1);
        if (parentObject) {
          setParentObject((prev) => {
            const newObj = { ...prev };
            let currentLevel = newObj.condition.conditions; // Start at the first level

            // Iterate over the indexPath array, except for the last element
            for (let i = 0; i < indexPath.length - 1; i++) {
              // Ensure that the next level exists or create it if necessary
              if (!currentLevel[indexPath[i]].conditions) {
                currentLevel[indexPath[i]].conditions = [];
              }
              // Move to the next level
              currentLevel = currentLevel[indexPath[i]].conditions;
            }
            // Update the last level with the updated object
            currentLevel[indexPath[indexPath.length - 1]] =
              updatedObj1.condition;
            return newObj;
          });
        }
        setRuleData((prevObject) => {
          const newObject = { ...prevObject };
          const updatedCondition = modifyConditionsByLevel(
            newObject.condition,
            indexPath,
            ob,
            "add"
          );
          return { ...prevObject, condition: updatedCondition };
        });
        break;
      case "R":
        let updatedObj2 = {
          ...obj,
          condition: {
            ...obj.condition,
            conditions: obj.condition.conditions
              .map((i) => {
                if (i?.id === item?.id) {
                  return null;
                }
                return i;
              })
              .filter((subCondition) => subCondition !== null),
          },
        };
        if (
          obj?.condition?.id &&
          item?.condition?.id &&
          obj?.condition?.id === item?.condition?.id
        ) {
          setObj(null);
        } else {
          setObj(updatedObj2);
        }
        if (parentObject) {
          setParentObject((prev) => {
            const newObj = { ...prev };
            let currentLevel = newObj.condition.conditions; // Start at the first level
            // Iterate over the indexPath array, except for the last element
            for (let i = 0; i < indexPath.length - 1; i++) {
              // Ensure that the next level exists or create it if necessary
              if (!currentLevel[indexPath[i]].conditions) {
                currentLevel[indexPath[i]].conditions = [];
              }
              // Move to the next level
              currentLevel = currentLevel[indexPath[i]].conditions;
            }
            // Update the last level with the updated object
            currentLevel[indexPath[indexPath.length - 1]] =
              obj?.condition?.id &&
              item?.condition?.id &&
              obj?.condition?.id === item?.condition?.id
                ? null
                : updatedObj2.condition;
            return {
              condition: {
                ...newObj.condition,
                conditions: newObj.condition.conditions.filter(
                  (subCondition) => subCondition !== null
                ),
              },
            };
          });
        }
        setRuleData((prevObject) => {
          const newObject = { ...prevObject };
          const updatedCondition = modifyConditionsByLevel(
            newObject.condition,
            indexPath,
            item,
            "delete"
          );
          return { ...prevObject, condition: updatedCondition };
        });
        break;
      default:
        break;
    }
  };

  const onChangeCallback = (value, item, label) => {
    const updatedObj = {
      ...obj,
      condition: {
        ...obj.condition,
        conditions: obj.condition.conditions.map((o) => {
          if (o?.id === item?.id) {
            return { ...o, [label]: value[label] };
          } else return o;
        }),
      },
    };

    setObj(updatedObj);
    setRuleData((prevObject) => {
      const newObject = { ...prevObject };
      const updatedCondition = modifyConditionsByLevel(
        newObject.condition,
        indexPath,
        value,
        "update"
      );
      return { ...prevObject, condition: updatedCondition };
    });
  };
  const [andText, setAndText] = useState(t("AND"));
  const [orText, setOrText] = useState(t("OR"));
  const handleTextChange = (e, type) => {
    const newValue = e.target.innerText.trim();
    if (type === "AND") {
      setAndText(newValue);
      if (nestedOperation === andText) {
        setNestedOperation(newValue);
      }
    } else {
      setOrText(newValue);
      if (nestedOperation === orText) {
        setNestedOperation(newValue);
      }
    }
  };

  const handleToggleChange = () => {
    setNestedOperation(nestedOperation === andText ? orText : andText);
  };

  return (
    <div>
      {obj ? (
        <div>
          <div className={isNested ? "" : "rules-condition-parent-container"}>
            <div>
              {/* style={{ width: "100%" }} */}
              <ul className="rules-condition-container" key={ruleId}>
                <div className={isNested ? "add-nested-condition-hr-line" : ""}>
                  <div className="rules-header">
                    <p
                      contentEditable
                      suppressContentEditableWarning
                      className="and-text"
                      style={{
                        fontWeight:
                          nestedOperation === andText ? "bold" : "normal",
                        padding: "5px",
                      }}
                      onBlur={(e) => handleTextChange(e, "AND")}
                    >
                      {andText}
                    </p>
                    <div onClick={handleToggleChange}>
                      <input
                        className="apple-switch text-primary"
                        type="checkbox"
                        checked={nestedOperation === orText}
                        readOnly
                      />
                    </div>
                    <p
                      contentEditable
                      suppressContentEditableWarning
                      className="or-text"
                      style={{
                        fontWeight:
                          nestedOperation === orText ? "bold" : "normal",
                        padding: "5px",
                      }}
                      onBlur={(e) => handleTextChange(e, "OR")}
                    >
                      {orText}
                    </p>

                    <button
                      className="btn btn-sm btn-flex btn-primary"
                      disabled={!isEditing}
                      onClick={() => handleClick("S", obj)}
                    >
                      {t("ADD_CONDITION")}
                    </button>
                    <button
                      className="btn btn-sm btn-flex btn-primary"
                      disabled={!isEditing}
                      onClick={(e) => handleClick("N", obj)}
                    >
                      {t("ADD_NESTED_CONDITION")}
                    </button>

                    <div
                      disabled={!isEditing}
                      color="error"
                      onClick={() => handleClick("R", obj, obj)}
                      variant="outlined"
                    >
                      <Delete />
                    </div>
                  </div>
                </div>

                <div className={"rules-condition-nested-container"}>
                  {obj?.condition?.conditions?.map((item, key) => {
                    const allOptionsValues = valuesMap?.get(item?.param) || [];

                    console.log(allOptionsValues, "all options");

                    const filteredOptions = allOptionsValues.filter((each) => {
                      //console.log(each, "each");
                      if (selectPaymentMethods?.length > 0) {
                        return selectPaymentMethods?.includes(each.label);
                      } else {
                        return savedPaymentMethodsUpdate?.includes(each.label);
                      }
                    });

                    const checkPaymentMethodParam =
                      item?.param === "paymentMethod";
                    const paramErrorText = includes(
                      item?.errorMessage,
                      "Param cannot be empty"
                      // t("PARAM_CAN'T_BE_EMPTY")
                    );

                    const operationErrorText = includes(
                      item?.errorMessage,
                      "Operation cannot be empty"
                      // t("OPERATION_CAN'T_BE_EMPTY")
                    );

                    const valueErrorText = includes(
                      item?.errorMessage,
                      "Value cannot be empty"
                      // t("VALUE_CAN'T_BE_EMPTY")
                    );

                    const param = item?.param;
                    const operation = item?.operation;
                    const value = item?.value;

                    const paramError = paramErrorText && !param;
                    const operationError = operationErrorText && !operation;
                    const valueError = valueErrorText && !value;

                    return item?.nestedOperation ? (
                      <>
                        <RulesCondition
                          key={item.id}
                          data={{ condition: item }}
                          level={level + 1}
                          setRuleData={setRuleData}
                          ruleData={ruleData}
                          indexPath={[...indexPath, key]}
                          parentObject={parentObject ? parentObject : obj}
                          setParentObject={
                            setParentObject ? setParentObject : setObj
                          }
                          conditionError={conditionError}
                          setConditionError={setConditionError}
                          ruleId={ruleId}
                          isEditing={isEditing}
                          isNested={true}
                        />
                      </>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <div
                          className="nested-operation"
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <div className="nested-element">
                            {`${nestedOperation}`}
                          </div>

                          <div className="rule-conditions-nested-container-parent">
                            <Group />

                            {/* <div
                              className="rule-condition-nested-elements"
                              style={{ width: "100%" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  minWidth: 150,
                                  position: "relative",
                                }}
                              >
                                <ElementDropdown
                                  className="form-control"
                                  data={item}
                                  key={`${item?.id}-param`}
                                  value={item?.param}
                                  dropdownData={paramDropDown}
                                  onChangeCallback={(value) => {
                                    onChangeCallback(value, item, "param");
                                  }}
                                  type="param"
                                  disabled={!isEditing}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  minWidth: 150,
                                  position: "relative",
                                }}
                              >
                                <ElementDropdown
                                  className="form-control"
                                  data={item}
                                  key={`${item?.id}-operation`}
                                  value={item?.operation}
                                  dropdownData={operationsMap?.get(item?.param)}
                                  onChangeCallback={(value) =>
                                    onChangeCallback(value, item, "operation")
                                  }
                                  type="operation"
                                  disabled={
                                    isEditing && item?.param ? false : true
                                  }
                                />
                              </div>
                              {valuesMap?.get(item?.param) ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    minWidth: 150,
                                  }}
                                >
                                  <MultiSelect
                                    selectErrorMsg={selectErrorMsg}
                                    key={`${item?.id}-value`}
                                    options={
                                      checkPaymentMethodParam
                                        ? filteredOptions
                                        : valuesMap?.get(item?.param) || []
                                    }
                                    selectedValues={
                                      item?.value
                                        ?.split(",")
                                        ?.map((i) => ({ label: i, value: i }))
                                        ?.filter((i) => i.value !== "") || []
                                    }
                                    onChange={(value) => {
                                      const updatedValue = value
                                        .filter((i) => i.value !== "")
                                        .map((i) => i.value)
                                        .join();

                                      const newVal = {
                                        ...item,
                                        value: updatedValue,
                                      };
                                      onChangeCallback(newVal, item, "value");
                                    }}
                                    // error={includes(
                                    //   item?.errorMessage,
                                    //   "Value cannot be empty"
                                    // )}
                                    disabled={
                                      item?.param
                                        ? item.operation
                                          ? false
                                          : true
                                        : false
                                    }
                                    type="value"
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    // width: "30%",
                                    display: "flex",
                                    flexDirection: "column",
                                    minWidth: 150,
                                  }}
                                >
                                  <input
                                    size="small"
                                    // sx={{ minWidth: 180 }}
                                    className="form-control padding"
                                    placeholder={t("ENTER_NUMBER")}
                                    value={item?.value}
                                    // onChange={(e) => {
                                    //   onChangeCallback(
                                    //     { ...item, value: e.target.value },
                                    //     item,
                                    //     "value"
                                    //   );
                                    // }}

                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      if (
                                        newValue === "" ||
                                        /^[0-9][0-9]*$/.test(newValue)
                                      ) {
                                        onChangeCallback(
                                          { ...item, value: newValue },
                                          item,
                                          "value"
                                        );
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === "-" ||
                                        (!/^[0-9]$/.test(e.key) &&
                                          e.key !== "Backspace")
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    type="number"
                                    // error={
                                    //   includes(
                                    //     item?.errorMessage,
                                    //     "Value cannot be empty"
                                    //   ) && selectErrorMsg
                                    // }
                                    disabled={
                                      isEditing && item?.param ? false : true
                                    }
                                  />
                                </div>
                              )}
                              <div
                                color="error"
                                variant="outlined"
                                onClick={() => handleClick("R", obj, item)}
                                disabled={!isEditing}
                              >
                                <Delete />
                              </div>
                              {<onProcessor />}
                            </div> */}
                            <div
                              className="rule-condition-nested-elements"
                              style={{ width: "100%" }}
                            >
                              {/* Param Dropdown */}
                              <div className="rules-dropdown-container">
                                <ElementDropdown
                                  className="form-control"
                                  data={item}
                                  key={`${item?.id}-param`}
                                  value={item?.param}
                                  dropdownData={paramDropDown}
                                  onChangeCallback={(value) =>
                                    onChangeCallback(value, item, "param")
                                  }
                                  type="param"
                                  disabled={!isEditing}
                                />
                                {paramError && (
                                  <p className="rules-dropdown-error-message">
                                    {t("PARAM_CAN'T_BE_EMPTY")}
                                  </p>
                                )}
                              </div>

                              {/* Operation Dropdown */}
                              <div className="rules-dropdown-container">
                                <ElementDropdown
                                  className="form-control"
                                  data={item}
                                  key={`${item?.id}-operation`}
                                  value={item?.operation}
                                  dropdownData={operationsMap?.get(item?.param)}
                                  onChangeCallback={(value) =>
                                    onChangeCallback(value, item, "operation")
                                  }
                                  type="operation"
                                  disabled={
                                    isEditing && item?.param ? false : true
                                  }
                                />
                                {operationError && (
                                  <p className="rules-dropdown-error-message">
                                    {t("OPERATION_CAN'T_BE_EMPTY")}
                                  </p>
                                )}
                              </div>

                              {/* Value Input or MultiSelect */}
                              <div className="rules-dropdown-container">
                                {valuesMap?.get(item?.param) ? (
                                  <MultiSelect
                                    selectErrorMsg={selectErrorMsg}
                                    key={`${item?.id}-value`}
                                    options={
                                      checkPaymentMethodParam
                                        ? filteredOptions
                                        : valuesMap?.get(item?.param) || []
                                    }
                                    selectedValues={
                                      item?.value
                                        ?.split(",")
                                        ?.map((i) => ({ label: i, value: i }))
                                        ?.filter((i) => i.value !== "") || []
                                    }
                                    onChange={(value) => {
                                      const updatedValue = value
                                        .filter((i) => i.value !== "")
                                        .map((i) => i.value)
                                        .join();

                                      const newVal = {
                                        ...item,
                                        value: updatedValue,
                                      };
                                      onChangeCallback(newVal, item, "value");
                                    }}
                                    disabled={
                                      item?.param
                                        ? item.operation
                                          ? false
                                          : true
                                        : false
                                    }
                                    type="value"
                                  />
                                ) : (
                                  <input
                                    className="form-control padding"
                                    placeholder={t("ENTER_NUMBER")}
                                    value={item?.value}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      if (
                                        newValue === "" ||
                                        /^[0-9][0-9]*$/.test(newValue)
                                      ) {
                                        onChangeCallback(
                                          { ...item, value: newValue },
                                          item,
                                          "value"
                                        );
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === "-" ||
                                        (!/^[0-9]$/.test(e.key) &&
                                          e.key !== "Backspace")
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    type="number"
                                    disabled={
                                      isEditing && item?.param ? false : true
                                    }
                                  />
                                )}
                                {valueError && (
                                  <p className="rules-dropdown-error-message">
                                    {t("VALUE_CAN'T_BE_EMPTY")}
                                  </p>
                                )}
                              </div>

                              <div
                                style={{
                                  color: "error",
                                  variant: "outlined",
                                  onClick: () => handleClick("R", obj, item),
                                  disabled: !isEditing,
                                }}
                              >
                                <Delete />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div
                          style={{
                            marginLeft: "-30px",
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <div style={{ width: "99px" }}></div>

                          <div
                            style={{ width: "100%", display: "flex", gap: 10 }}
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <p className={paramError ? "error-text" : "text"}>
                                {t("PARAM_CAN'T_BE_EMPTY")}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <p
                                className={
                                  operationError
                                    ? paramError
                                      ? "error-text"
                                      : "operation-error-message"
                                    : "text"
                                }
                              >
                                {t("OPERATION_CAN'T_BE_EMPTY")}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                minWidth: "150px",
                              }}
                            >
                              <p
                                className={
                                  valueError
                                    ? paramError
                                      ? "error-text"
                                      : operationError
                                      ? "error-text"
                                      : "value-error-message"
                                    : "text"
                                }
                              >
                                {" "}
                                {t("VALUE_CAN'T_BE_EMPTY")}
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    );
                  })}
                </div>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default RulesCondition;
