
import { isProd } from "constants/Constants";
export const prodLinks = {
  STRIPE: "https://dashboard.stripe.com/dashboard",
};
export const testLinks = {
  STRIPE: "https://dashboard.stripe.com/test/dashboard",
};

export const processors = [
  {
    displayName: "Midtrans",
    processorCode: "MIDTRANS",
    logo_url: "logos/midTransLogo-new.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          `<ol class="ps-3"><li> Login to Midtrans Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</li>
          <li>Navigate to Access Keys under Settings section from left side menu.</li>
          <li>Copy the Merchant ID, Client Key and Secret Key and configure Payorch Midtrans API credentials block.</li></ol>`,
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Midtrans Dashboard (<a  style="color:blue; text-decoration:none" href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</li>
          <li>Navigate to Payment section under Settings section from left side menu.</li>
          <li>Click on Payment Settings -&gt; Notification URL</li>
          <li>Configure the following webhook URL, for Payment Notification URL<br>  <a>https://api.prtest.payorch.com/midtrans/MERCHANT_ID/webhooks</a></li></ol>`
        ],
      },
      {
        title: "Setup Return URLs",
        content: [
          `<ol class="ps-3"><li> Login to Midtrans Dashboard (<a  style="color:blue; text-decoration:none" href="https://dashboard.sandbox.midtrans.com/" target="_blank">https://dashboard.sandbox.midtrans.com/</a>)</li>
          <li>Navigate to Payment section under Settings section from the left side menu.</li>
          <li>Click on Payment Settings -&gt; Finish Redirect URL</li>
          <li>Configure the following redirect URL for Finish Redirect URL:<br>  <a >https://api.prtest.payorch.com/return/midtrans?merchantid=MERCHANT_ID</a></li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Adyen",
    processorCode: "ADYEN",
    logo_url: "logos/adyen.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a  style="color:blue; text-decoration:none"  href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
             <li>Navigate to API credentials under Developers section from the left side menu.</li>
             <li>Create new credentials of type Web service user, if not already exists.</li>
             <li>Configure sufficient roles for the API while generating. Basic roles require all the webhook-related roles under the ACCOUNT section and all the roles under UNCATEGORIZED.</li>
             <li>Copy the username and API Key and configure them in the Payorch Adyen API credentials block.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a style="color:blue; text-decoration:none" href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
            <li>Navigate to Webhooks under the Developers section from the left side menu.</li>
            <li>Add a new webhook configuration. Select Standard Webhook and choose all the event types.</li>
            <li>Configure the following webhook URL for Payment Notification URL:<br>  <a>https://api.prtest.payorch.com/adyen/MERCHANT_ID/webhooks</a></li></ol>`
        ],
      },
      {
        title: "Configure Additional Data",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a style="color:blue; text-decoration:none" href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
          <li>Navigate to Additional data under the Developers section from the left side menu.</li>
          <li>Select all the required data. Bank, Card, and 3DSecure are mandatory.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "XENDIT",
    processorCode: "XENDIT",
    logo_url: "logos/xendit-logo.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          `<ol class="ps-3"><li>Login to Xendit Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.xendit.co/home" target="_blank">https://dashboard.xendit.co/home</a>)</li>
           <li>Select Settings under Configuration section on the left side menu.</li>
           <li>Go to the Developers block and click API keys.</li>
           <li>Click on Generate Secret key. While generating the secret key, select Write permissions for Money-in (required for payments) and Money-out (required for payments) products.</li>
           <li>Copy the API Key and set it to the API key in the Payorch Xendit API credentials block.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Xendit Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.xendit.co/home" target="_blank">https://dashboard.xendit.co/home</a>)</li>
           <li>Select Settings under Configuration section on the left side menu.</li>
           <li>Go to the Developers block and click Webhooks.</li>
           <li>Set the following webhook URL for the following products: FIXED VIRTUAL ACCOUNTS, RETAIL OUTLETS (OTC), CARDS, PAYMENT REQUEST, Unified Refunds, PAYLATER, QR CODES, DISBURSEMENT, and E-WALLETS.<br> <a>https://api.prtest.payorch.com/xendit/MERCHANT_ID/webhooks</a></li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "DOKU",
    processorCode: "DOKU",
    logo_url: "logos/doku-logo.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to DOKU Dashboard (<a style="color:blue; text-decoration:none" href="https://sandbox.doku.com/bo/login" target="_blank">https://sandbox.doku.com/bo/login</a>)</li>
           <li>Navigate to API Keys under the Integration section from the left side menu.</li>
           <li>Copy the Client ID and Secret Key and configure them in the Payorch DOKU API credentials block.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "IPaymu",
    processorCode: "IPAYMU",
    logo_url: "logos/ipaymu-logo.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to IPaymu Dashboard (<a style="color:blue; text-decoration:none" href="https://my.ipaymu.com/dashboard" target="_blank">https://my.ipaymu.com/dashboard</a>)</li>
           <li>Navigate to API Integration from the left side menu.</li>
           <li>Copy the VA Account number and API Key, and configure them in the Payorch IPaymu API credentials.</li></ol>`
        ],
      },
    ],
  },
  // {
  //   displayName: "Stripe",
  //   processorCode: "STRIPE",
  //   logo_url: "logos/STRIPE.svg",
  //   info: [
  //     {
  //       title: "Fetch API Credentials",
  //       content: [
  //         "<p>1. Login to Stripe Dashboard (<a href='https://dashboard.stripe.com/test/dashboard' target='_blank'>https://dashboard.stripe.com/test/dashboard</a>)</p>",
  //         "<p>2. Go to Developers section and API Keys tab.</p>",
  //         "<p>3. Copy the Secret key, and configure them in the Payorch Stripe credentials section. If there is no secret key exists, please generate.</p>",
  //       ],
  //     },
  //     {
  //       title: "Setup Webhooks",
  //       content: [
  //         "<p>1. Login to Stripe Dashboard (<a href='https://dashboard.stripe.com/test/dashboard' target='_blank'>https://dashboard.stripe.com/test/dashboard</a>)</p>",
  //         "<p>2. Go to Developers section and Webhooks tab.</p>",
  //         "<p>3. Click on Add Endpoint and set the Endpoint URL to <a>https://api.prtest.payorch.com/stripe/MERCHANT_ID/webhooks</a></p>",
  //         "<p>4. Select all teh events under Charge, Mandate, Payment Intent, Payout.</p>",
  //       ],
  //     }
  //   ],
  // },
  {
    displayName: "Checkout",
    processorCode: "CHECKOUT",
    logo_url: "logos/CHECKOUT.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
         `<ol class="ps-3"><li>Login to Checkout.com Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.sandbox.checkout.com/" target="_blank">https://dashboard.sandbox.checkout.com/</a>)</li>
          <li>Go to Keys tab under Developers section.</li>
          <li>Copy the Secret key, and configure them in the Payorch Checkout.com credentials section. If there is no secret key exists, please generate.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Checkout.com Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.sandbox.checkout.com/" target="_blank">https://dashboard.sandbox.checkout.com/</a>)</li>
           <li>Go to Webhooks tab under Developers section.</li>
           <li>3. Click on new Webhook and set the Endpoint URL to <a>https://api.prtest.payorch.com/checkout/MERCHANT_ID/webhooks</a></li>
           <li>Select all the events for categories Card Payout, Gateway and sessions.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Tap",
    processorCode: "TAP",
    logo_url: "logos/TAP.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
         `<ol class="ps-3"><li>Login to Tap Dashboard (<a style="color:blue; text-decoration:none" href="https://businesses.tap.company" target="_blank">https://businesses.tap.company</a>)</li>
           <li>Go to goSell tab and section API Credentials.</li>
           <li>Copy the Secret key, and configure them in the Payorch Tap credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
         `<ol class="ps-3"><li>No manual configuration is required for webhok setup.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Myfatoorah",
    processorCode: "MYFATOORAH",
    logo_url: "logos/MYFATOORAH.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Myfatoorah Dashboard (<a style="color:blue; text-decoration:none" href="https://demo.myfatoorah.com/En/All/Account/LogIn" target="_blank">https://demo.myfatoorah.com/En/All/Account/LogIn</a>)</li>
           <li>Go to API Key under Integration Setting section.</li>
           <li>Copy the Token, and configure them in the Payorch Myfatoorah credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Myfatoorah Dashboard (<a style="color:blue; text-decoration:none" href="https://demo.myfatoorah.com/En/All/Account/LogIn" target="_blank">https://demo.myfatoorah.com/En/All/Account/LogIn</a>)</li>
           <li>Go to Webhook Settings under Integration Setting section.</li>
           <li>Enable Webhook and select all the webhook events.</a></li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Moyasar",
    processorCode: "MOYASAR",
    logo_url: "logos/MOYASAR.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Moyasar Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.moyasar.com/login/new" target="_blank">https://dashboard.moyasar.com/login/new</a>)</>
           <li>2. Go to Settings section and API Keys tab.</li>
           <li>Copy the Secret key of respective environment, and configure them in the Payorch Moyasar credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Moyasar Dashboard (<a style="color:blue; text-decoration:none" href="https://dashboard.moyasar.com/login/new" target="_blank">https://dashboard.moyasar.com/login/new</a>)</li>
           <li>Go to Settings section and Webhooks tab.</li>
           <li>Click on Add Webhook and set the Endpoint URL to <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Cybersource",
    processorCode: "CYBERSOURCE",
    logo_url: "logos/CYBERSOURCE.svg",
    description: "Accepts Cards, Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Cybersource Dashboard</li>
          <li>Go to Administartion -> Account Data -> Merchant /Channel section.</li>
          <li>Copy the credentials of respective environment, and configure them in the Payorch Cybersource credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Cybersource Dashboard</li>
          <li>Go to Administration -> Webhooks section.</li>
          <li>Set the Endpoint URL to <a>https://api.prtest.payorch.com/cybersource/MERCHANT_ID/webhooks</a></li>
          <li>Select the notification type PAYMENTS and select all the fields.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Hyperpay",
    processorCode: "HYPERPAY",
    logo_url: "logos/HYPERPAY.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
         `<ol class="ps-3"><li>Login to HyperPay Dashboard</li>
          <li>Go to Administartion -> Account Data -> Merchant /Channel section.</li>
          <li>Copy the access token of respective environment, and configure them in the Payorch Hyperpay credentials section.</li>
          <li>Get the Merchant Entity Identifier from Hyperpay team and configure them in the Payorch Hyperpay credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Hyperpay Dashboard</li>
           <li>Go to Administration -> Webhooks section.</li>
           <li>Set the Endpoint URL to <a>https://api.prtest.payorch.com/hyperpay/MERCHANT_ID/webhooks</a></li>
           <li>Select the notification type PAYMENTS and select all the fields.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "StcPay",
    processorCode: "STCPAY",
    logo_url: "logos/STCPAY.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Get the Client Id, Client Secret from StcPay Dashboard and configure in Payorch StcPay credenrials section.</li>
           <li>Configure the Branch Id, Teller Id, Device Id and the URL provided by StcPay.</li><ol>`
        ],
      },
    ],
  },
  {
    displayName: "Paytabs",
    processorCode: "PAYTABS",
    logo_url: "logos/PAYTABS.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Paytabs Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Paytabs credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Paytabs credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Paytabs Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Paytabs team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "CxPay",
    processorCode: "CXPAY",
    baseProcessorCode: "NMI",
    logo_url: "logos/cxpay.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to CxPay Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch CxPay credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch CxPay credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to CxPay Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact CxPay team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "T1 Payments",
    processorCode: "T1PAYMENTS",
    baseProcessorCode: "NMI",
    logo_url: "logos/t1_payments.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to T1payments Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch T1payments credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch T1payments credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to T1payments Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact T1payments team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Fortis",
    processorCode: "FORTIS",
    baseProcessorCode: "NMI",
    logo_url: "logos/PAYTABS.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Fortis Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Fortis credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Fortis credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Fortis Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Fortis team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Payscape",
    processorCode: "PAYSCAPE",
    baseProcessorCode: "NMI",
    logo_url: "logos/payscape.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Payscape Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Payscape credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Payscape credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Payscape Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Payscape team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Durango Merchant Services",
    processorCode: "DURANGO",
    baseProcessorCode: "NMI",
    logo_url: "logos/durango.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Durango Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Durango credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Durango credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Durango Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Durango team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "National Processing",
    processorCode: "NP_BANK",
    baseProcessorCode: "NMI",
    logo_url: "logos/national_processing.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to NpBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch NpBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch NpBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to NpBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact NpBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Viking",
    processorCode: "VIKING",
    baseProcessorCode: "NMI",
    logo_url: "logos/viking-pay.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Viking Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Viking credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Viking credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Viking Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Viking team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "SkyBank Financial",
    processorCode: "SKY_BANK",
    baseProcessorCode: "NMI",
    logo_url: "logos/skybank.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to SkyBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch SkyBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch SkyBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to SkyBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact SkyBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Dharma",
    processorCode: "DHARMA",
    baseProcessorCode: "NMI",
    logo_url: "logos/dharma.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Dharma Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Dharma credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Dharma credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Dharma Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paytabs.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Dharma team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Payline",
    processorCode: "PAYLINE",
    baseProcessorCode: "NMI",
    logo_url: "logos/payline.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Payline Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.payline.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Payline credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Payline credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Payline Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.payline.sa/merchant/login" target="_blank">https://merchant.paytabs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Payline team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "PayKings",
    processorCode: "PAYKINGS",
    baseProcessorCode: "NMI",
    logo_url: "logos/paykings.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Paykings Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Paykings credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Paykings credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Paykings Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Paykings team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Network Online",
    processorCode: "NWIN_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/paykings.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to NwinBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.nwinbank.sa/merchant/login" target="_blank">https://merchant.nwinbank.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch NwinBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch NwinBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to NwinBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.nwinbank.sa/merchant/login" target="_blank">https://merchant.nwinbank.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact NwinBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Suncorp Bank",
    processorCode: "SUNCORP_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/suncorp.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to SuncorpBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch SuncorpBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch SuncorpBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to SuncorpBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact SuncorpBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Banque Saudi Fransi",
    processorCode: "BSFR_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/paykings.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to BsfrBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch BsfrBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch BsfrBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to BsfrBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact BsfrBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Doha Bank",
    processorCode: "DOHA_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/doha_bank.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to DohaBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch DohaBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch DohaBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to DohaBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact DohaBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "ANZ Bank",
    processorCode: "ANZB_BANK",
    baseProcessorCode: "Cybersource",
    logo_url: "logos/anz.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to AnzbBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch AnzbBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch AnzbBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to AnzbBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact AnzbBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Bank of America",
    processorCode: "BOA_BANK",
    baseProcessorCode: "Cybersource",
    logo_url: "logos/bank_of_america.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to BoaBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch BoaBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch BoaBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to BoaBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact BoaBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Union Bank",
    processorCode: "UNION_BANK",
    baseProcessorCode: "Cybersource",
    logo_url: "logos/union_bank.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to UnionBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch UnionBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch UnionBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to UnionBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact UnionBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Kenya Commercial Bank(KCB)",
    processorCode: "KCB_BANK",
    baseProcessorCode: "Cybersource",
    logo_url: "logos/kcb_bank.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to KcbBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch KcbBank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch KcbBank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to KcbBank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.paykings.sa/merchant/login" target="_blank">https://merchant.paykings.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact KcbBank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Abu Dhabi Commercial Bank",
    processorCode: "ADCB_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/adcb_gateway.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to ADCB bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.adcb.sa/merchant/login" target="_blank">https://merchant.adcb.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch ADCB bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch ADCB bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to ADCB bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.adcb.sa/merchant/login" target="_blank">https://merchant.adcb.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact ADCB bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Arab Financial Services",
    processorCode: "AFS_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/afs_gateway.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to AFS Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.afs.sa/merchant/login" target="_blank">https://merchant.afs.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch AFS credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch AFS credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to AFS Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.afs.sa/merchant/login" target="_blank">https://merchant.afs.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact AFS team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Areeba",
    processorCode: "AREEBA_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/areeba_gateway.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Areeba Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.areeba.sa/merchant/login" target="_blank">https://merchant.areeba.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Areeba credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Areeba credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Areeba Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.areeba.sa/merchant/login" target="_blank">https://merchant.areeba.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Areeba team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Axis Bank",
    processorCode: "AXIS_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/axis_bank.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Axis Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.axis.sa/merchant/login" target="_blank">https://merchant.axis.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Axis Bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Axis Bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Axis Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.axis.sa/merchant/login" target="_blank">https://merchant.axis.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Axis Bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Bank Alfalah",
    processorCode: "ALFALAH_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/alfalah_pgw.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Alfalah Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.alfalah.sa/merchant/login" target="_blank">https://merchant.alfalah.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Alfalah credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Alfalah credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Alfalah Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.alfalah.sa/merchant/login" target="_blank">https://merchant.alfalah.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Alfalah team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Bank Audi ",
    processorCode: "AUDI_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/bank_audi.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Audi Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.audibank.sa/merchant/login" target="_blank">https://merchant.audibank.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Audi Bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Audi Bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Audi Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.audibank.sa/merchant/login" target="_blank">https://merchant.audibank.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Audi Bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Abu Dhabi Islamic Bank ",
    processorCode: "ADIB_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/adib_pgw.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Abudhabi Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.adibbank.sa/merchant/login" target="_blank">https://merchant.adibbank.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Abudhabi Bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Abudhabi Bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Abudhabi Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.adibbank.sa/merchant/login" target="_blank">https://merchant.adibbank.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Abudhabi Bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Bank Of Beirut",
    processorCode: "BABE_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/bank_of_beirut.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Bank of Beirut Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.babebank.sa/merchant/login" target="_blank">https://merchant.babebank.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Bank of Beirut credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Bank of Beirut credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Bank of Beirut Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.babebank.sa/merchant/login" target="_blank">https://merchant.babebank.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Bank of Beirut team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Banque Libano-Française",
    processorCode: "BLFS_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/banque_libano.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to BLFS Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.blfsbank.sa/merchant/login" target="_blank">https://merchant.blfsbank.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch BLFS Bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch BLFS Bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to BLFS Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.blfsbank.sa/merchant/login" target="_blank">https://merchant.blfsbank.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact BLFS Bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Bendigo Bank ",
    processorCode: "BEND_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/bendigo_bank.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Bend Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.bendbank.sa/merchant/login" target="_blank">https://merchant.bendbank.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Bend Bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Bend Bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Bend Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.bendbank.sa/merchant/login" target="_blank">https://merchant.bendbank.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Bend Bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Burgan Bank",
    processorCode: "BRGN_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/burgan_bank.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Burgan bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.burganbank.sa/merchant/login" target="_blank">https://merchant.burganbank.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Burgan bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Burgan bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Burgan bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.burganbank.sa/merchant/login" target="_blank">https://merchant.burganbank.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Burgan bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Commercial Bank of Ceylon",
    processorCode: "CCEY_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/commercial_bank_of_ceylon.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Commericial Bank Of ceylon Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.commercialbankceylon.sa/merchant/login" target="_blank">https://merchant.commercialbankceylon.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Commericial Bank Of ceylon credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Commericial Bank Of ceylon credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Commericial Bank Of ceylon Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.commercialbankceylon.sa/merchant/login" target="_blank">https://merchant.commercialbankceylon.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Commericial Bank Of ceylon team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "CrediMax ",
    processorCode: "CREDIMAX",
    baseProcessorCode: "MPGS",
    logo_url: "logos/credimax_pgw.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Credimax Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.credimax.sa/merchant/login" target="_blank">https://merchant.credimax.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Credimax credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Credimax credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Credimax Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.credimax.sa/merchant/login" target="_blank">https://merchant.credimax.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Credimax team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "HSBC Bank ",
    processorCode: "HSBC_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/hsbc_pgw.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to HSBC Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.hsbc.sa/merchant/login" target="_blank">https://merchant.hsbc.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch HSBC Bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch HSBC Bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to HSBC Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.hsbc.sa/merchant/login" target="_blank">https://merchant.hsbc.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact HSBC Bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Kuwait Finance House",
    processorCode: "KFHO_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/kuwait_finance_house.svg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Kuwait Finance House Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.kfho.sa/merchant/login" target="_blank">https://merchant.kfho.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Kuwait Finance House credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Kuwait Finance House credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Kuwait Finance House Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.kfho.sa/merchant/login" target="_blank">https://merchant.kfho.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Kuwait Finance House team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Global Payments",
    processorCode: "GLOBALPMT",
    baseProcessorCode: "MPGS",
    logo_url: "logos/global_payments_mpgs.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Global Payment Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.globalpayment.sa/merchant/login" target="_blank">https://merchant.globalpayment.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Global Payment credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Global Payment credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Global Payment Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.globalpayment.sa/merchant/login" target="_blank">https://merchant.globalpayment.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Global Payment team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Mashreq Bank ",
    processorCode: "MSHQ_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/mashreq_new.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Mashreq Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.mashreq.sa/merchant/login" target="_blank">https://merchant.mashreq.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Mashreq Bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Mashreq Bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Mashreq Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.mashreq.sa/merchant/login" target="_blank">https://merchant.mashreq.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Mashreq Bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Mauritius Commercial Bank(MCB)",
    processorCode: "MCBL_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/mcb.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Mauritius commercial Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.mcbl.sa/merchant/login" target="_blank">https://merchant.mcbl.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Mauritius commercial Bank credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Mauritius commercial Bank credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Mauritius commercial Bank Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.mcbl.sa/merchant/login" target="_blank">https://merchant.mcbl.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Mauritius commercial Bank team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "MCB eGate",
    processorCode: "MCB_EGATE",
    baseProcessorCode: "MPGS",
    logo_url: "logos/mcb_egate.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to MCB  Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.mcb.sa/merchant/login" target="_blank">https://merchant.mcb.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch MCB  credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch MCB  credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to MCB  Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.mcb.sa/merchant/login" target="_blank">https://merchant.mcb.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact MCB  team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "National Bank Of Egypt",
    processorCode: "NBEG_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/national_bank_of_egypt.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to National Bank of Egypt Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.nbeg.sa/merchant/login" target="_blank">https://merchant.nbeg.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch National Bank of Egypt credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch National Bank of Egypt credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to National Bank of Egypt Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.nbeg.sa/merchant/login" target="_blank">https://merchant.nbeg.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact National Bank of Egypt team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "National Bank of Oman",
    processorCode: "NBOM_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/nbo.jpg",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to National Bank of Oman Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.nbom.sa/merchant/login" target="_blank">https://merchant.nbom.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch National Bank of Oman credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch National Bank of Oman credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to National Bank of Oman Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.nbom.sa/merchant/login" target="_blank">https://merchant.nbom.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact National Bank of Oman team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Commercial Bank of Kuwait",
    processorCode: "COMBKW_BANK",
    baseProcessorCode: "MPGS",
    logo_url: "logos/commercial_bank_of_kuwait.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Credentials",
        content: [
          `<ol class="ps-3"><li>Login to Commericial bank of kuwait Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.commercialkuwait.sa/merchant/login" target="_blank">https://merchant.commercialkuwait.sa/merchant/login</a>)</li>
           <li>Go to API Keys & Key Management section under Developers.</li>
           <li>Copy the Server Key of API Key and configure them in the Payorch Commericial bank of kuwait credentials section.</li>
           <li>Go to Profile section, copy the Profile ID and configure them in the Payorch Commericial bank of kuwait credentials section.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Commericial bank of kuwait Dashboard (<a style="color:blue; text-decoration:none" href="https://merchant.commercialkuwait.sa/merchant/login" target="_blank">https://merchant.commercialkuwait.sa/merchant/login</a>)</li>
           <li>Select Developers Menu. Go to Configuration section under Payment Notification group.</li>
           <li>Add new IPN Config Details. Select Type as 'Default Web'. Add URL as <a>https://api.prtest.payorch.com/moyasar/MERCHANT_ID/webhooks</a></li>
           <li>Select all the allowed events and save the configuration.</a></li></ol>`
        ],
      },
      {
        title: "Setup PCI DSS Certificates",
        content: [
          `<ol class="ps-3"><li>Get the Payorch PCI Certification details, ASV reports and Payorch server IP addresses from Payorch team.</li>
           <li>Contact Commericial bank of kuwait team to enable sending direct raw card details in to their API and whitelist the Payorch IP address.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "Stripe",
    processorCode: "STRIPE",
    logo_url: "logos/stripe.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a  style="color:blue; text-decoration:none"  href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
             <li>Navigate to API credentials under Developers section from the left side menu.</li>
             <li>Create new credentials of type Web service user, if not already exists.</li>
             <li>Configure sufficient roles for the API while generating. Basic roles require all the webhook-related roles under the ACCOUNT section and all the roles under UNCATEGORIZED.</li>
             <li>Copy the username and API Key and configure them in the Payorch Adyen API credentials block.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a style="color:blue; text-decoration:none" href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
            <li>Navigate to Webhooks under the Developers section from the left side menu.</li>
            <li>Add a new webhook configuration. Select Standard Webhook and choose all the event types.</li>
            <li>Configure the following webhook URL for Payment Notification URL:<br>  <a>https://api.prtest.payorch.com/adyen/MERCHANT_ID/webhooks</a></li></ol>`
        ],
      },
      {
        title: "Configure Additional Data",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a style="color:blue; text-decoration:none" href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
          <li>Navigate to Additional data under the Developers section from the left side menu.</li>
          <li>Select all the required data. Bank, Card, and 3DSecure are mandatory.</li></ol>`
        ],
      },
    ],
  },
  {
    displayName: "National Bank of Kuwait",
    processorCode: "NBOK_BANK_MPGS",
    logo_url: "logos/national_bank_of_kuwait_pgw.png",
    description: "Accepts Bank transfer, wallets and more.",
    info: [
      {
        title: "Fetch API Keys",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a  style="color:blue; text-decoration:none"  href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
             <li>Navigate to API credentials under Developers section from the left side menu.</li>
             <li>Create new credentials of type Web service user, if not already exists.</li>
             <li>Configure sufficient roles for the API while generating. Basic roles require all the webhook-related roles under the ACCOUNT section and all the roles under UNCATEGORIZED.</li>
             <li>Copy the username and API Key and configure them in the Payorch Adyen API credentials block.</li></ol>`
        ],
      },
      {
        title: "Setup Webhooks",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a style="color:blue; text-decoration:none" href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
            <li>Navigate to Webhooks under the Developers section from the left side menu.</li>
            <li>Add a new webhook configuration. Select Standard Webhook and choose all the event types.</li>
            <li>Configure the following webhook URL for Payment Notification URL:<br>  <a>https://api.prtest.payorch.com/adyen/MERCHANT_ID/webhooks</a></li></ol>`
        ],
      },
      {
        title: "Configure Additional Data",
        content: [
          `<ol class="ps-3"><li>Login to Adyen Dashboard (<a style="color:blue; text-decoration:none" href="https://ca-test.adyen.com/ca/ca/" target="_blank">https://ca-test.adyen.com/ca/ca/</a>)</li>
          <li>Navigate to Additional data under the Developers section from the left side menu.</li>
          <li>Select all the required data. Bank, Card, and 3DSecure are mandatory.</li></ol>`
        ],
      },
    ],
  },
];
export const ProcessorIcon = ({ processorCode }) => {
  const processor = processors.find(
    (p) => p.displayName?.toLowerCase() === processorCode?.toLowerCase()
  );

  if (processor) {
    console.log(processor);
    return <img src={processor.logo_url} alt={processor.displayName} />;
  }
};
