import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { httpClient } from "utils/HttpClient";
import Tooltip from "@mui/material/Tooltip";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { ProcessorBreadcrumbIcon } from "../Icons/Processor";
import Plus from "../Icons/Plus";
import Close from "components/Icons/Close";
import FilterIcon from "../Icons/Filter";
import Sort from "../Icons/Sort";
import CaretDown from "../Icons/CaretDown";
import "./Settings.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

import {
  URL,
  MERCHANT_ID,
  UPDATE_PROCESSOR_ENDPOINT,
  PROCESSORLIST_ENDPOINT,
  PROCESSOR_ENDPOINT,
  MERCHANT_CONFIG_ENDPOINT,
} from "constants/Constants";
import AddEditProcessorSettingCard from "components/Elements/ProcessorSettingCard/AddEditProcessorSettingCard";
import AddPaymentProcessorModal from "components/AddPaymentProcessorModal/AddPaymentProcessorModal";
import { processors } from "utils/processors";
import Pencil from "components/Icons/Pencil";
import Swap from "components/Icons/Swap";
import { Typography } from "@mui/material";
import Delete from "components/Icons/Delete";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { StyledTableRow } from "utils/Styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F7FA",
    color: "#525866",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    padding: "8px 12px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#0E121B",
    padding: "12px 20px 12px 12px;",
  },
}));



const Chip = ({ title, status }) => {
  const captialize = (value) => {
    return (
      value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase()
    ).replaceAll("_", " ");
  };

  return (
    <div className="chip-container">
      <div className="chip-status" />
      <div className="chip-title">{captialize(title)}</div>
    </div>
  );
};

const CheckCircle = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM10.8538 6.85375L7.35375 10.3538C7.30732 10.4002 7.25217 10.4371 7.19147 10.4623C7.13077 10.4874 7.06571 10.5004 7 10.5004C6.9343 10.5004 6.86923 10.4874 6.80853 10.4623C6.74783 10.4371 6.69269 10.4002 6.64625 10.3538L5.14625 8.85375C5.05243 8.75993 4.99972 8.63268 4.99972 8.5C4.99972 8.36732 5.05243 8.24007 5.14625 8.14625C5.24007 8.05243 5.36732 7.99972 5.5 7.99972C5.63268 7.99972 5.75993 8.05243 5.85375 8.14625L7 9.29313L10.1463 6.14625C10.1927 6.09979 10.2479 6.06294 10.3086 6.0378C10.3693 6.01266 10.4343 5.99972 10.5 5.99972C10.5657 5.99972 10.6308 6.01266 10.6915 6.0378C10.7521 6.06294 10.8073 6.09979 10.8538 6.14625C10.9002 6.1927 10.9371 6.24786 10.9622 6.30855C10.9873 6.36925 11.0003 6.4343 11.0003 6.5C11.0003 6.5657 10.9873 6.63075 10.9622 6.69145C10.9371 6.75214 10.9002 6.8073 10.8538 6.85375Z"
      fill="#1FC16B"
    />
  </svg>
);

const Inactive = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM10.3538 10.3538C10.3073 10.4002 10.2522 10.4371 10.1915 10.4623C10.1308 10.4874 10.0657 10.5004 10 10.5004C9.9343 10.5004 9.86923 10.4874 9.80853 10.4623C9.74783 10.4371 9.69269 10.4002 9.64625 10.3538L5.64625 6.35375C5.55243 6.25993 5.49972 6.13268 5.49972 6C5.49972 5.86732 5.55243 5.74007 5.64625 5.64625C5.74007 5.55243 5.86732 5.49972 6 5.49972C6.13268 5.49972 6.25993 5.55243 6.35375 5.64625L10.3538 9.64625C10.4002 9.69269 10.4371 9.74783 10.4623 9.80853C10.4874 9.86923 10.5004 9.93429 10.5004 10C10.5004 10.0657 10.4874 10.1308 10.4623 10.1915C10.4371 10.2522 10.4002 10.3073 10.3538 10.3538Z"
      fill="#FB3748"
    />
  </svg>
);

const Status = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`status-container ${
        title.toLocaleLowerCase() === "active"
          ? "active-status"
          : "inactive-status"
      }`}
    >
      <div className="status-title">
        {title.toLocaleLowerCase() === "active" ? t("ACTIVE") : t("INACTIVE")}
      </div>
    </div>
  );
};

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  z-index: 1;
  min-width: 110px;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
);

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }
  `
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border: 0;
  background-color: white;
  `
);

const Ellipsis = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.9375 10C10.9375 10.1854 10.8825 10.3667 10.7795 10.5208C10.6765 10.675 10.5301 10.7952 10.3588 10.8661C10.1875 10.9371 9.99896 10.9557 9.8171 10.9195C9.63525 10.8833 9.4682 10.794 9.33709 10.6629C9.20598 10.5318 9.11669 10.3648 9.08051 10.1829C9.04434 10.001 9.06291 9.81254 9.13386 9.64123C9.20482 9.46993 9.32498 9.32351 9.47915 9.2205C9.63332 9.11748 9.81458 9.0625 10 9.0625C10.2486 9.0625 10.4871 9.16127 10.6629 9.33709C10.8387 9.5129 10.9375 9.75136 10.9375 10ZM10 5.625C10.1854 5.625 10.3667 5.57002 10.5208 5.467C10.675 5.36399 10.7952 5.21757 10.8661 5.04627C10.9371 4.87496 10.9557 4.68646 10.9195 4.5046C10.8833 4.32275 10.794 4.1557 10.6629 4.02459C10.5318 3.89348 10.3648 3.80419 10.1829 3.76801C10.001 3.73184 9.81254 3.75041 9.64123 3.82136C9.46993 3.89232 9.32351 4.01248 9.2205 4.16665C9.11748 4.32082 9.0625 4.50208 9.0625 4.6875C9.0625 4.93614 9.16127 5.1746 9.33709 5.35041C9.5129 5.52623 9.75136 5.625 10 5.625ZM10 14.375C9.81458 14.375 9.63332 14.43 9.47915 14.533C9.32498 14.636 9.20482 14.7824 9.13386 14.9537C9.06291 15.125 9.04434 15.3135 9.08051 15.4954C9.11669 15.6773 9.20598 15.8443 9.33709 15.9754C9.4682 16.1065 9.63525 16.1958 9.8171 16.232C9.99896 16.2682 10.1875 16.2496 10.3588 16.1786C10.5301 16.1077 10.6765 15.9875 10.7795 15.8333C10.8825 15.6792 10.9375 15.4979 10.9375 15.3125C10.9375 15.0639 10.8387 14.8254 10.6629 14.6496C10.4871 14.4738 10.2486 14.375 10 14.375Z"
      fill="#0E121B"
    />
  </svg>
);

// const ActionMenu = ({
//   index,
//   isOpen,
//   toggleDropdown,
//   item,
//   setShowProcessorSettingCard,
//   setSelectedItem,
//   onStatusClick,
//   fetchSettingsData,
//   setEditProcessorAccountData,
//   setShowConfirmationDeletePopup,
//   setDeleteProcessorItem,
// }) => {
//   const createHandleMenuClick = (menuItem, id) => {
//     if (menuItem === "Edit") {
//       setShowProcessorSettingCard(true);
//       setSelectedItem(item);
//       httpClient
//         .fetchData(`${URL}${UPDATE_PROCESSOR_ENDPOINT}/${id}`, "GET")
//         .then((res) => {
//           setEditProcessorAccountData(res);
//           console.log("edit processor responsee:", res);
//         })
//         .catch((error) => {
//           console.error("Error fetch processor account:", error);
//         });
//     }
//     if (menuItem === "Disable") {
//       onStatusClick(item.id, item.status);
//     }
//   };

//   const handleDeleteProcessor = (processor) => {
//     httpClient
//       .fetchData(`${URL}${UPDATE_PROCESSOR_ENDPOINT}/${processor.id}`, "DELETE")
//       .then((res) => {
//         console.log("Deleted processor", res);
//         fetchSettingsData();
//       })
//       .catch((error) => {
//         console.error("Error Deleting Processor: ", error);
//       });
//   };

//   const confirmationDeletePopup = (item) => {
//     console.log("Delete testing");
//     setShowConfirmationDeletePopup(true);
//     setDeleteProcessorItem(item);
//   };

//   return (
//     <Dropdown>
//       <MenuButton>
//         <Ellipsis />
//       </MenuButton>
//       <Menu slots={{ listbox: AnimatedListbox }}>
//         <MenuItem onClick={() => createHandleMenuClick("Edit", item.id)}>
//           <span className="action-menu-item ">
//             <span className="action-menu-icon">
//               <Pencil />
//             </span>
//             Edit
//           </span>
//         </MenuItem>
//         <MenuItem onClick={() => createHandleMenuClick("Disable")}>
//           <span className="action-menu-item ">
//             <span className="action-menu-icon">
//               <Swap />
//             </span>
//             {item.status === "ACTIVE" ? "Disable" : "Activate"}
//           </span>
//         </MenuItem>
//         {item.status === "ACTIVE" ? (
//           <MenuItem
//             onClick={() => confirmationDeletePopup(item)} //handleActiveProcessorDelete("Disable", item)
//           >
//             <span className="action-menu-item ">
//               <span className="action-menu-icon">
//                 <Delete />
//               </span>
//               Remove
//             </span>
//           </MenuItem>
//         ) : (
//           ""
//         )}
//         {item.status === "DISABLED" ? ( // handleDeleteProcessor(item)
//           <MenuItem onClick={() => confirmationDeletePopup(item)}>
//             <span className="action-menu-item ">
//               <span className="action-menu-icon">
//                 <Delete />
//               </span>
//               Remove
//             </span>
//           </MenuItem>
//         ) : (
//           ""
//         )}
//       </Menu>
//     </Dropdown>
//   );
// };

const ActionMenu = ({
  index,
  isOpen,
  toggleDropdown,
  item,
  setShowProcessorSettingCard,
  setSelectedItem,
  onStatusClick,
  fetchSettingsData,
  setEditProcessorAccountData,
  setShowConfirmationDeletePopup,
  setshowConfirmationDisablePopup,
  setDeleteProcessorItem,
  setDisableProcessorItem
}) => {
  const { t } = useTranslation();
  const handleOutsideClick = (e) => {
    if (!e.target.closest(".dropdown-menu-list-items")) {
      toggleDropdown(null);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  const createHandleMenuClick = (menuItem, id) => {
    if (menuItem === "Edit") {
      setShowProcessorSettingCard(true);

      setSelectedItem(item);
    }
    if (menuItem === "Disable") {
      setshowConfirmationDisablePopup(true);
    //  onStatusClick(item.id, item.status);
    }
  };

  const handleDeleteProcessor = (processor) => {
    httpClient
      .fetchData(`${URL}${UPDATE_PROCESSOR_ENDPOINT}/${processor.id}`, "DELETE")
      .then((res) => {
        console.log("Deleted processor", res);
        fetchSettingsData();
      })
      .catch((error) => {
        console.error("Error Deleting Processor: ", error);
      });
  };

  const confirmationDeletePopup = (item) => {
    console.log("Delete testing");
    setShowConfirmationDeletePopup(true);
    setDeleteProcessorItem(item);
  };
  const disablePopUp = (item) => {
    //createHandleMenuClick("Disable",item.id)
    setshowConfirmationDisablePopup(true);   
    setDisableProcessorItem(item)
  }
  return (
    <div
      className="dropdown-menu-list-items"
      onClick={() => toggleDropdown(index)}
    >
      <Button className="action-menu-button">
        <Ellipsis />
      </Button>

      {isOpen && (
        <div className={isOpen ? "menu" : "close-dropdown-menu-container-body"}>
          <div
            className="menu-item"
            onClick={() => createHandleMenuClick("Edit", item.id)}
          >
            <span className="action-menu-item">
              <span className="action-menu-icon">
                <Pencil />
              </span>
              <span>{t("EDIT")}</span>
            </span>
          </div>

          <div
            className="menu-item"
            onClick={() => disablePopUp(item)
            }
          >
            <span className="action-menu-item">
              <span className="action-menu-icon">
                <Swap />
              </span>
              <span>
                {" "}
                {item.status === "ACTIVE" ? t("DISABLE") : t("ACTIVATE")}
              </span>
            </span>
          </div>

          {(item.status === "ACTIVE" || item.status === "DISABLED") && (
            <div
              className="menu-item"
              onClick={() => confirmationDeletePopup(item)}
            >
              <span className="action-menu-item">
                <span className="action-menu-icon">
                  <Delete />
                </span>
                <span> {t("REMOVE")} </span>
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const CustomizedTables = ({
  setShowProcessorSettingCard,
  setSelectedItem,
  data,
  onStatusClick,
  title,
  selectedFilter,
  fetchSettingsData,
  setEditProcessorAccountData,
  setShowEditFilterNames,
  setShowConfirmationDeletePopup,
  setshowConfirmationDisablePopup,
  setDeleteProcessorItem,
  setDisableProcessorItem
  // deleteItem,
  // deleteConfirm,
  // setDeleteConfirm,
}) => {
  const [openDropdown, setOpenDropdown] = React.useState(null);
  const { t } = useTranslation();

  const toggleDropdown = (index) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const captialize = (value) => {
    return (
      value?.charAt(0)?.toUpperCase() + value?.slice(1)?.toLocaleLowerCase()
    );
  };

  const filteredData = (data || []).filter((item) => {
    if (selectedFilter === 0) {
      return true;
    } else if (selectedFilter === 1) {
      return item?.status?.toLowerCase() === "active";
    } else if (selectedFilter === 2) {
      return (
        item?.status?.toLowerCase() === "inactive" ||
        item?.status?.toLowerCase() === "disabled"
      );
    }
    return false;
  });

  const hasActiveOrInactive = filteredData.some(
    (item) =>
      item?.status?.toLowerCase() === "active" ||
      item?.status?.toLowerCase() === "inactive" ||
      item?.status?.toLowerCase() === "disabled"
  );

  return (
    <TableContainer
      className="table-responsive"
      component={Paper}
      sx={{ display: "flex", flexWrap: "wrap", overflowX: "scroll" }}
    >
      <Table
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        sx={{ minWidth: 300, overflowX: "scroll" }}
        aria-label="customized table"
      >
        {hasActiveOrInactive && (
          <TableHead>
            <TableRow className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              <StyledTableCell className="table-header-text">
                {t("PAYMENT_PROCESSOR")}
              </StyledTableCell>
              <StyledTableCell className="table-header-text">
                {t("NAME")}
              </StyledTableCell>
              <StyledTableCell className="table-header-text">
                {t("PAYMENT_METHODS")}
              </StyledTableCell>
              <StyledTableCell className="table-header-text">
                {t("STATUS")}
              </StyledTableCell>
              <StyledTableCell className="table-header-text"></StyledTableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {filteredData
            ?.sort((a, b) => {
              return a.processorCode.localeCompare(b.processorCode);
            })
            .map((item, index) => {
              const processor = processors.find(
                (processor) =>
                  processor?.processorCode?.toLowerCase() ===
                  item?.processorCode?.toLowerCase()
              );
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    <div className="settings-title-container">
                      <div className="settings-img-container">
                        <img
                          loading="lazy"
                          src={processor?.logo_url}
                          className="settings-img"
                        />
                      </div>
                      <span className="settings-title">
                        {captialize(item.processorCode)}
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className="settings-title">
                    {" "}
                    {captialize(item?.name || "")}
                  </StyledTableCell>

                  <StyledTableCell>
                    {(item.paymentMethods || []).map((paymentType) => (
                      <Chip title={paymentType} />
                    ))}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Status title={item.status} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <ActionMenu
                      index={index}
                      isOpen={openDropdown === index}
                      toggleDropdown={toggleDropdown}
                      item={item}
                      setShowProcessorSettingCard={setShowProcessorSettingCard}
                      setSelectedItem={setSelectedItem}
                      onStatusClick={onStatusClick}
                      fetchSettingsData={fetchSettingsData}
                      setEditProcessorAccountData={setEditProcessorAccountData}
                      setShowEditFilterNames={setShowEditFilterNames}
                      setShowConfirmationDeletePopup={
                        setShowConfirmationDeletePopup
                      }
                      setshowConfirmationDisablePopup = {setshowConfirmationDisablePopup}
                      setDeleteProcessorItem={setDeleteProcessorItem}
                      setDisableProcessorItem={setDisableProcessorItem}
                      // deleteItem={deleteItem}
                      // deleteConfirm={deleteConfirm}
                      // setDeleteConfirm={setDeleteConfirm}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FilterTabs = styled(Tabs)({
  border: 0,
  backgroundColor: "#F5F7FA",
  "& .MuiTabs-indicator": {
    height: 0,
  },
  minHeight: "36px !important",
  maxHeight: "36px !important",
});

const FilterTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: "80px !important", // 100px
    minHeight: "34px !important",
    maxHeight: "34px !important",
    color: "#717784",
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    "&.Mui-selected": {
      borderRadius: "6px",
      color: "#0E121B",
      backgroundColor: "white",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const Filter = ({ selectedFilter, onFilterChange }) => {
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    onFilterChange(newValue);
  };

  return (
    <>
      <div className="filter-container">
        <div className="filter-toggle-container">
          <FilterTabs value={selectedFilter} onChange={handleChange}>
            <FilterTab className="btn filter-tabs" label={t("ALL")} />
            <FilterTab className="btn filter-tabs" label={t("ACTIVE")} />
            <FilterTab className="btn filter-tabs " label={t("INACTIVE")} />
          </FilterTabs>
        </div>

        <div className="filter-action-container">
          {/* <div className="filter-action-item">
            <FilterIcon />
            <div className="filter-action-title">Filter</div>
          </div>
          <div className="filter-action-item">
            <Sort />
            <div className="filter-action-title">Sort by</div>
            <CaretDown />
          </div> */}
        </div>
      </div>
    </>
  );
};

const Header = ({ onClick, title, disable }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          <div className="header-title">{t("PROCESSOR")}</div>
          <div className="header-sub-title">{t("INITIAL_TRACK")}</div>
        </div>
        <Tooltip
          title={
            disable
              ? "No new processors left to configure"
              : "Add Payment Processor"
          }
        >
          <div
            className={`card-toolbar ${disable ? "disabled" : ""}`}
            onClick={() => !disable && onClick()}
          >
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary">
                <i class="ki-duotone ki-plus fs-4"></i>
                {t("PAYMENT_PROCESSOR")}
              </button>
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

const Settings = () => {
  const { t } = useTranslation();
  const [showPaymentProcessor, setShowPaymentProcessor] = React.useState(false);
  const [showProcessorSettingCard, setShowProcessorSettingCard] =
    React.useState(false);
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: t("PROCESSOR"), component: <ProcessorBreadcrumbIcon /> },
  ]);
  const [getBackendProcessorData, setGetBackendProcessorData] = React.useState(
    []
  );
  const [selectedItem, setSelectedItem] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(true);
  const [actionDisabled, setActionDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState(0);
  const [selectedNewProcessor, setSelectedNewProcessor] = React.useState();
  const [filterProcessorNames, setFilterProcessorNames] = React.useState([]);
  const [deleteProcessorItem, setDeleteProcessorItem] = React.useState();
  const [disableProcessorItem,setDisableProcessorItem] = React.useState();
  const [showEditNameDisabled, setShowEditNameDisabled] = React.useState(false);
  const [showConfirmationDeletePopup, setShowConfirmationDeletePopup] = React.useState(false);
  const [showConfirmationDisablePopup,setshowConfirmationDisablePopup] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState();
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const modalRef = React.useRef();

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowConfirmationDeletePopup(false);
        setshowConfirmationDisablePopup(false);
      }
    };

    if (showConfirmationDeletePopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    if(showConfirmationDisablePopup){
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showConfirmationDeletePopup, setShowConfirmationDeletePopup,showConfirmationDisablePopup,setshowConfirmationDisablePopup]);

  // console.log(showConfirmationDeletePopup, "popup");
  console.log(deleteProcessorItem, "deleteProcessor");

  const onPaymentProcess = () => {
    setShowPaymentProcessor(true);
  };

  const [data, setData] = React.useState();
  /**New function for displaying processor name needs to be changed later when we get the processor name via response */
  const displayname = () => {
    let processorDisplayname;
    // processors
    getBackendProcessorData?.forEach((processors) => {
      const processorlist = {
        displayname: processors?.displayName,
        code: processors?.processorCode,
      };
      if (selectedItem.processorCode === processorlist.code) {
        processorDisplayname = processorlist.displayname;
      }
    });
    return processorDisplayname;
  };
  React.useEffect(() => {
    fetchSettingsData();
  }, []);
  React.useEffect(() => {
    if (showProcessorSettingCard)
      setBreadcrumbList([
        {
          title: `Processor / ${
            isEdit ? "Edit" : "Add"
          } ${displayname()} Payment Processor`,
          component: <ProcessorBreadcrumbIcon />,
        },
      ]);
    else
      setBreadcrumbList([
        { title: "Processor", component: <ProcessorBreadcrumbIcon /> },
      ]);
  }, [showProcessorSettingCard]);

  const fetchSettingsData = () => {
    setIsLoading(true);
    setData([]);
    httpClient
      .fetchData(
        // token,
        `${URL}${PROCESSORLIST_ENDPOINT}`,
        "GET"
      )
      .then((data) => {
        console.log(data, "res data");
        setData(data);
        const processorList = processors.filter((setting) => {
          return !data?.find(
            (item) =>
              item.processorCode.toLowerCase() ===
              setting.processorCode.toLowerCase()
          );
        });
        setActionDisabled(processorList.length === 0);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const onStatusClick = (processorId, currentStatus) => {
    setIsLoading(true);
    setshowConfirmationDisablePopup(false);
    const statusData = {
      status: currentStatus === "ACTIVE" ? "DISABLED" : "ACTIVE",
    };

    httpClient
      .fetchData(
        `${URL}${UPDATE_PROCESSOR_ENDPOINT}/${processorId}`,
        "PATCH",
        statusData
      )
      .then(() => {
        fetchSettingsData();
      })
      .catch((error) => {
        fetchSettingsData();
      });
  };

  React.useEffect(() => {
    const namesArray =
      data
        ?.filter((eachItem) => eachItem?.processorCode === selectedNewProcessor)
        .map((eachItem) => eachItem?.name?.toLowerCase()) || [];

    setFilterProcessorNames(namesArray);
  }, [selectedNewProcessor, data]);
  console.log(filterProcessorNames, "names");

  React.useEffect(() => {
    const editProcessorDisabledName = data?.find(
      (eachItem) => eachItem.id === selectedItem.id
    );
    if (editProcessorDisabledName) {
      setShowEditNameDisabled(true);
    }
    console.log(editProcessorDisabledName, "matchId");
  }, [selectedItem]);

  console.log(deleteItem, "delete");

  const handleActiveProcessorDelete = (menuItem, processor) => {
    console.log(menuItem, "status");
    if (menuItem === "Disable") {
      const statusData = {
        status:
          processor.status === "ACTIVE"
            ? "DISABLED"
            : processor.status === "DISABLED"
            ? "DISABLED"
            : "ACTIVE",
      };

      httpClient
        .fetchData(
          `${URL}${UPDATE_PROCESSOR_ENDPOINT}/${processor.id}`,
          "PATCH",
          statusData
        )
        .then(() => {
          // handleDeleteProcessor(processor);
          httpClient
            .fetchData(
              `${URL}${UPDATE_PROCESSOR_ENDPOINT}/${processor.id}`,
              "DELETE"
            )
            .then((res) => {
              console.log("Deleted processor", res);
              fetchSettingsData();
              setShowConfirmationDeletePopup(false);
            })
            .catch((error) => {
              console.error("Error Deleting Processor: ", error);
            });
        })
        .catch((error) => {
          fetchSettingsData();
        });
    }
  };

  return (
    <>
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="toolbar py-2" id="kt_toolbar">
          <div
            id="kt_toolbar_container"
            className="container-fluid container-fluid d-flex align-items-center"
          >
            <Breadcrumb
              list={breadcrumbList}
              onClick={() => {
                setShowProcessorSettingCard(false);
                setIsEdit(true);
              }}
            />
          </div>
        </div>
        <div id="kt_content_container" className="container-xxl">
          <div className="card pt-6">
            {!showProcessorSettingCard ? (
              <>
                <Header
                  onClick={onPaymentProcess}
                  title={data?.name || "Demo Merchant"}
                  disable={actionDisabled}
                />
                {isLoading ? (
                  <div className="spinner-container">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <>
                    <div className="card-body py-4">
                      <Filter
                        onFilterChange={($event) => setSelectedFilter($event)}
                        selectedFilter={selectedFilter}
                      />
                      <CustomizedTables
                        selectedFilter={selectedFilter}
                        title={data?.name || "Demo Merchant"}
                        data={data}
                        setIsEdit={setIsEdit}
                        setShowProcessorSettingCard={
                          setShowProcessorSettingCard
                        }
                        setSelectedItem={setSelectedItem}
                        onStatusClick={onStatusClick}
                        fetchSettingsData={fetchSettingsData}
                        setShowConfirmationDeletePopup={
                          setShowConfirmationDeletePopup
                        }
                        setshowConfirmationDisablePopup = {setshowConfirmationDisablePopup}
                        setDeleteProcessorItem={setDeleteProcessorItem}
                        setDisableProcessorItem={setDisableProcessorItem}
                        // deleteItem={deleteItem}
                        // deleteConfirm={deleteConfirm}
                        // setDeleteConfirm={setDeleteConfirm}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <AddEditProcessorSettingCard
                isEdit={isEdit}
                data={selectedItem}
                filterProcessorNames={filterProcessorNames}
                showEditNameDisabled={showEditNameDisabled}
                getBackendProcessorData={getBackendProcessorData}
                onSave={() => {
                  setShowProcessorSettingCard(false);
                  setIsEdit(true);
                  fetchSettingsData();
                  setShowEditNameDisabled(false);
                  // setDeleteConfirm(false);
                }}
                onCancel={() => {
                  setShowProcessorSettingCard(false);
                  setIsEdit(true);
                  fetchSettingsData();
                  setShowEditNameDisabled(false);
                  // setDeleteConfirm(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {showPaymentProcessor && (
        <AddPaymentProcessorModal
          processorData={data}
          open={showPaymentProcessor}
          setOpen={(e) => setShowPaymentProcessor(e)}
          setSelectedItem={setSelectedItem}
          setGetBackendProcessorData={setGetBackendProcessorData}
          addPayment={(getSelectData) => {
            setIsEdit(false);
            setShowPaymentProcessor(false);
            setShowProcessorSettingCard(true);
            setSelectedNewProcessor(getSelectData);
          }}
        />
      )}
      <>
        {showConfirmationDeletePopup && (
          <div className="modal">
            <div className="modal-content p-4" ref={modalRef}>
              <p className="popup-description">
                {t("ARE_YOU_SURE_YOU_WANT_TO_REMOVE_CONNECTION")}&nbsp;
                <span style={{ fontWeight: "bold" }}>
                  {deleteProcessorItem?.name?.charAt(0)?.toUpperCase() +
                    deleteProcessorItem?.name?.slice(1)?.toLowerCase()}
                </span>
                &nbsp;({t("PROCESSOR")} {' '}
                {/* <span style={{ fontWeight: "bold" }}> */}
                {deleteProcessorItem?.processorCode?.charAt(0)?.toUpperCase() +
                  deleteProcessorItem?.processorCode?.slice(1)?.toLowerCase()}
                {/* </span> */}
                )?
              </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "15px",
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleActiveProcessorDelete("Disable", deleteProcessorItem);
                  }}
                >
                  {t("OK")}
                </button>
                <button
                  className="btn btn-light-primary"
                  onClick={() => {
                    setShowConfirmationDeletePopup(false);
                  }}
                >
                  {t("CANCEL")}
                </button>
              </div>
            </div>
          </div>
        )}
      </>

      <>
        {showConfirmationDisablePopup && (
          <div className="modal">
            <div className="modal-content p-4" ref={modalRef}>
              <p className="popup-description">
                {t("ARE_YOU_SURE_YOU_WANT_TO")}&nbsp;
                <span style={{ fontWeight: "bold" }}>
                  {(disableProcessorItem.status === 'ACTIVE' ? 'DISABLE' : 'ENABLE') + ' ' + disableProcessorItem?.name?.charAt(0)?.toUpperCase() +
                    disableProcessorItem?.name?.slice(1)?.toLowerCase()}
                </span>
                &nbsp;({t("PROCESSOR")} {' '}
                {/* <span style={{ fontWeight: "bold" }}> */}
               {disableProcessorItem?.processorCode?.charAt(0)?.toUpperCase() +
                  disableProcessorItem?.processorCode?.slice(1)?.toLowerCase()}
                )?
              </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "15px",
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    onStatusClick(disableProcessorItem.id,disableProcessorItem.status)
                  }}
                >
                  {t("OK")}
                </button>
                <button
                  className="btn btn-light-primary"
                  onClick={() => {
                    setshowConfirmationDisablePopup(false);
                  }}
                >
                  {t("CANCEL")}
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default Settings;
