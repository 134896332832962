import React, { useState, useEffect } from "react";
import {
  Checkbox,
  TextField,
  Grid,
  Box,
  Typography,
  FormControlLabel,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import "./Webhooks.css";
import webhookEvents from "../../../utils/WebhookEvents";
import { httpClient } from "utils/HttpClient";
import { URL, WEBHOOK_SETTINGS_ENDPOINT } from "constants/Constants";
import Logout from "Logout";
import SaveIcon from "../../Icons/FloppyDisk";
import { useTranslation } from "react-i18next";

function Webhooks() {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookProperties, setWebhookProperties] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState(true);
  const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
  const { t } = useTranslation();

  const errorMessage =
    webhookUrl && webhookUrl?.includes("https://") && webhookUrl?.length > 15;
  // webhookUrl.startsWith("https://");
  console.log(errorMessage, "message");

  console.log(isValidUrl, "url");

  useEffect(() => {
    if (
      typeof webhookUrl === "string" &&
      webhookUrl?.includes("https://") &&
      webhookUrl?.length > 15
    ) {
      // startsWith("https://")
      setIsValidUrl(true);
      setSaveButtonStatus(false);
    } else {
      setIsValidUrl(false);
    }
  }, [webhookUrl]);

  useEffect(() => {
    fetchWebhookEventsData();
  }, []);

  const fetchWebhookEventsData = async () => {
    try {
      const data = await httpClient.fetchData(
        `${URL}${WEBHOOK_SETTINGS_ENDPOINT}`,
        "GET"
      );
      console.log(data);
      setWebhookUrl(data?.webhookUrl);
      setWebhookProperties(data?.webhookProperties || {});
    } catch (error) {
      console.error("Error fetching webhook data:", error);
    }
  };

  console.log(requiredErrorMessage, "message");

  const handleSave = async () => {
    if (webhookUrl === "") {
      setRequiredErrorMessage(true);
    } else {
      setRequiredErrorMessage(true);
    }

    if (isValidUrl === true) {
      console.log("Save Button");
      try {
        setIsLoading(true);
        const response = await httpClient.fetchData(
          `${URL}${WEBHOOK_SETTINGS_ENDPOINT}`,
          "POST",
          {
            webhookUrl,
            webhookProperties,
          }
        );
        if (response.ok) {
          console.log("get response");
          return response.json();
        } else {
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          } else {
            console.log("Failed saving data:", response.status);
          }
        }
      } catch (error) {
        console.error("Error saving webhook data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (eventType, category) => {
    setWebhookProperties((prevProperties) => ({
      ...prevProperties,
      [eventType]: !prevProperties[eventType],
    }));
  };

  return (
    <div>
      <Grid>
        <Box
          className="d-flex justify-content-between align-items-center mb-4"
          // sx={{
          //   display: "flex",
          //   justifyContent: "space-between",
          //   alignItems: "center",
          //   marginBottom: "20px",
          // }}
        >
          <Typography className="webhooks-text">{t("WEBHOOKS")}</Typography>
          <Grid
            item
            xs={12}
            // sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <LoadingButton
              loading={isLoading}
              // sx={{ marginTop: "10px" }}
              variant="contained"
              onClick={handleSave}
              // className="webhook-save-button"
              className="btn btn-primary"
              startIcon={<SaveIcon />}
              //disabled={saveButtonStatus}
            >
              {t("SAVE")}
            </LoadingButton>
          </Grid>
        </Box>
        {/* <Box className="webhook-and-search-url-container">
          <Grid className="content-label">
            <Typography className="webhook-url-text">
              {t("WEBHOOK_URL")}
            </Typography>
          </Grid>
          <Box
            className="w-100"
           
          >
        
            <Grid>
              <input
              
                className="search-bar"
                fullWidth
                value={webhookUrl}
                onChange={(e) => setWebhookUrl(e.target.value)}
                placeholder={t("ADD_URL")}
              />
            </Grid>
            <p
              className={
                errorMessage
                  ? "hide-text"
                  : webhookUrl !== ""
                  ? "error-message"
                  : "hide-text"
              }
            >
              {t("ENTER_CORRECT_URL")}
            </p>
            <p
              className={
                requiredErrorMessage
                  ? webhookUrl?.length > 0
                    ? "hide-text"
                    : "error-message"
                  : "hide-text"
              }
            >
              {t("URL_IS_REQUIRED")}
            </p>
          </Box>
        </Box> */}
        <Box className="webhook-and-search-url-container">
          <Grid className="content-label">
            <Typography className="webhook-url-text">
              {t("WEBHOOK_URL")}
            </Typography>
          </Grid>
          <Box
            className="w-100"
            // sx={{ width: "100%" }}
          >
            <Grid>
              {/* <Grid className="search-layout-cont"> */}
              <input
                // size="small"
                // sx={{
                //   "& fieldset": { border: "none" },
                // }}
                className="search-bar"
                fullWidth
                value={webhookUrl || ""}
                onChange={(e) => setWebhookUrl(e.target.value)}
                placeholder={t("ADD_URL")}
              />
            </Grid>
            <p
              className={
                errorMessage
                  ? "hide-text"
                  : webhookUrl?.length > 0
                  ? "error-message"
                  : "hide-text"
              }
            >
              {t("ENTER_CORRECT_URL")}
            </p>
            <p
              className={
                requiredErrorMessage
                  ? webhookUrl?.length > 0
                    ? "hide-text"
                    : "error-message"
                  : "hide-text"
              }
            >
              {t("URL_IS_REQUIRED")}
            </p>
          </Box>
        </Box>

        <Box className="hr-line-label-container">
          <div className="hr-line"></div>
        </Box>

        {Object.entries(webhookEvents.webhookEvents).map(
          ([category, events]) => {
            const subTitle = () => {
              if (category === "PaymentInstrument") {
                return "Payment Instrument";
              } else {
                return category;
              }
            };
            console.log(category, "category");
            return (
              <Grid item xs={12} key={category}>
                {/* <Box className="payments-border-container"> */}
                <Box>
                  <Grid>
                    {/* <Typography className="payment-status-text"> */}
                    <Typography className="webhooks-text">
                      {subTitle()}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="payment-status-row-container">
                      {events.map((eventType) => (
                        <div
                          key={eventType}
                          className="each-checkbox-container col"
                        >
                          <FormControlLabel
                            className="checkbox-text"
                            control={
                              <Checkbox
                                checked={webhookProperties[eventType] || false}
                                onChange={() =>
                                  handleChange(eventType, category)
                                }
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 22,
                                  },
                                  color: "#E1E4EA",
                                  "&.Mui-checked": {
                                    color: "primary",
                                  },
                                  "& .MuiCheckbox-root": {
                                    borderRadius: "8px",
                                  },
                                }}
                              />
                            }
                            label={eventType.indexOf("_") > 0 ?  eventType.split('_').join(' '): eventType}
                          />
                        </div>
                      ))}
                    </div>
                  </Grid>
                </Box>
              </Grid>
            );
          }
        )}
        {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={isLoading}
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={handleSave}
            className="save-btn-text"
            startIcon={<SaveIcon />}
            disabled={saveButtonStatus}
          >
            Save
          </LoadingButton>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default Webhooks;
