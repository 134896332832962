import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Close from "../Icons/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "./StepsToIntegrateModal.css";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { processors } from "utils/processors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { styled } from "@mui/material/styles";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 616,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  maxHeight: "80vh",
  overflowY: "auto",
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  height: "32px",
  padding: "8px 12px 8px 12px",
  gap: "6px",
  borderRadius: "6px 0px 0px 0px",
  opacity: "0px",
  backgroundColor: "var(--bg-weak-50, #f5f7fa)",
  width: "584px",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  letterSpacing: "0.04em",
  textAlign: "left",
  color: "var(--text-sub-600, #525866)",
}));

const StepsToIntegrateModal = ({ open, setOpen, processorCode }) => {
  const MERCHANT_ID = useSelector((state) => state.merchant.merchantId);
  const [expanded, setExpanded] = useState(false);
  const processor = processors.find(
    (p) => p.processorCode.toLowerCase() === processorCode.toLowerCase()
  );
  const { t } = useTranslation();

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} sx={{ width: "auto" }}>
      <Box sx={style} className="steps-to-integrate-modal modal-content">
        <div className="modal-header">
          <span className="modal-header-container">
            {/* <HelpOutlineOutlinedIcon />s */}
            <div className="modal-header-title-container">
              <div className="modal-header-title fs-1 text-primary d-flex align-items-center">
                <i class="ki-duotone ki-question-2 fs-2 me-2">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                </i>
                {t("STEPS_TO_INTEGRATE")}
              </div>
            </div>
          </span>
          <span className="modal-header-close" onClick={() => setOpen(false)}>
            <Close />
          </span>
        </div>
        <Divider />
        {processor?.info?.length > 0 ? (
          <Box className="modal-body my-3">
            {processor?.info?.map((info, index) => (
              <Accordion
                className="modal-accordion"
                key={index}
                sx={{ marginBottom: "16px" }}
                onChange={handleChange(index)}
                expanded={expanded === index}
              >
                <StyledAccordionSummary
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  expandIcon={
                    expanded === index ? (
                      <RemoveCircleOutlineOutlinedIcon />
                    ) : (
                      <AddCircleOutlineIcon />
                    )
                  }
                >
                  <StyledTypography>
                    {" "}
                    {info.title.toUpperCase()}
                  </StyledTypography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  {info?.content?.map((content, idx) => {
                    return (
                      <Typography
                        className="dropdown-description-text"
                        key={idx}
                        dangerouslySetInnerHTML={{
                          __html: content.replace("MERCHANT_ID", MERCHANT_ID),
                        }}
                      />
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ) : (
          <Box className="modal-body my-3">
            <Typography className="dropdown-description-text">
              {t("PLEASE_CONTACT_SUPPORT")} integrations@payorch.com
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default StepsToIntegrateModal;
